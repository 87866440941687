import React from 'react';
import Box from '@material-ui/core/Box';
import ReactHtmlParser from 'react-html-parser';

export default function BookRef(props) {
  const prepareReference = (reference) => {
    return ReactHtmlParser(reference.replace('Kowal, M', '<b>Kowal, M</b>'));
  };

  return (
    <Box my={0}>
      <Box pb={2} className="ref wrap">
        {prepareReference(props.authors)} ({props.year}). <i>{props.title}</i>. {props.publisher}.
      </Box>
    </Box>
  );
}
