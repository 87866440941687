import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import withWidth from '@material-ui/core/withWidth';
import Box from '@material-ui/core/Box';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import PublicIcon from '@material-ui/icons/Public';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'left',
  },
}));

function MainMenu(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };
  return (
    <AppBar position="sticky" className={window.location.pathname === '/game' ? 'hideNav' : classes.root}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          <Link href="/" color="inherit">
            {' '}
            <HomeRoundedIcon className="icon" fontSize="small" /> MARTA KOWAL{' '}
          </Link>
        </Typography>
        {props.width === 'xs' || props.width === 'sm' ? (
          <Box>
            <Button role="presentation" onClick={toggleDrawer(true)} onKeyDown={toggleDrawer(false)}>
              <MenuIcon className="whiteFont" />
            </Button>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <Link href="/tls" color="inherit">
                <ListItem button key="LOVE">
                  <ListItemIcon>
                    <PublicIcon />
                  </ListItemIcon>
                  <ListItemText primary="LOVE" />
                </ListItem>
              </Link>
              <List>
                <Link href="/study" color="inherit">
                  <ListItem button key="International Study">
                    <ListItemIcon>
                      <PublicIcon />
                    </ListItemIcon>
                    <ListItemText primary="International Study" />
                  </ListItem>
                </Link>
                <Link href="/game" color="inherit">
                  <ListItem button key="E-attractive Game">
                    <ListItemIcon>
                      <SportsEsportsIcon />
                    </ListItemIcon>
                    <ListItemText primary="E-attractive Game" />
                  </ListItem>
                </Link>
                <Link href="/papers" color="inherit">
                  <ListItem button key="Publications">
                    <ListItemIcon>
                      <MenuBookIcon />
                    </ListItemIcon>
                    <ListItemText primary="Publications" />
                  </ListItem>
                </Link>
                <Link href="/about" color="inherit">
                  <ListItem button key="about">
                    <ListItemIcon>
                      <EmojiPeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="About me" />
                  </ListItem>
                </Link>
              </List>
            </Drawer>
          </Box>
        ) : (
          <Box>
            <Link href="/tls" color="inherit">
              <Button color="inherit">LOVE</Button>
            </Link>
            <Link href="/study" color="inherit">
              <Button color="inherit">International Study</Button>
            </Link>
            <Link href="/game" color="inherit">
              <Button color="inherit">E-attractive Game</Button>
            </Link>
            <Link href="/papers" color="inherit">
              <Button color="inherit">Publications</Button>
            </Link>
            <Link href="/students" color="inherit">
              <Button color="inherit">For Students</Button>
            </Link>
            <Link href="/about" color="inherit">
              <Button color="inherit">About me</Button>
            </Link>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}
export default withWidth()(MainMenu);
