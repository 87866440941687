import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { Button, Link } from '@material-ui/core';
import BasicInfo from './TestSteps/BasicInfo';
import Questions from './TestSteps/Questions';
import Results from './TestSteps/Results';
import $ from 'jquery';

export default function TestTLSlang() {
  const { lang } = useParams();
  const [step, setStep] = useState(0);
  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState({
    consent: false,
    lang: lang,
    gender: '',
    age: '',
    country: '',
    relationshipStatus: '',
    tls1: '',
    tls2: '',
    tls3: '',
    tls4: '',
    tls5: '',
    tls6: '',
    tls7: '',
    tls8: '',
    tls9: '',
    tls10: '',
    tls11: '',
    tls12: '',
    tls13: '',
    tls14: '',
    tls15: '',
  });
  const data = require('../TranslationsData.json');
  const LangData = data[lang.toLowerCase()] || data['eng'];

  const getUserData = async () => {
    return fetch('https://json.geoiplookup.io/')
      .then((response) => response.json())
      .catch((err) => console.log(err));
  };

  const handleSubmit = async () => {
    setFormData({ ...formData, userData: await getUserData() });
    let err = [];
    if (step === 0) {
      formData.gender === '' ? err.push('gender') : null;
      formData.age === '' ? err.push('age') : null;
      formData.country === '' || formData.country === null ? err.push('country') : null;
      formData.relationshipStatus === '' ? err.push('relationshipStatus') : null;
    }
    if (step === 1) {
      for (let i = 1; i <= 15; i++) {
        formData[`tls${i}`] === '' ? err.push(`tls${i}`) : null;
      }
    }
    setErrors(err);
    err.length > 0 ? null : setStep(step + 1);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const conditionalComponent = () => {
    switch (step) {
      case 0:
        return (
          <BasicInfo
            formData={formData}
            engData={data['eng']}
            setFormData={setFormData}
            errors={errors}
            langData={LangData}
            handleChange={handleChange}
          />
        );
      case 1:
        return <Questions formData={formData} errors={errors} langData={LangData} handleChange={handleChange} />;
      case 2:
        return <Results formData={formData} />;
      default:
        return <BasicInfo formData={formData} errors={errors} langData={LangData} handleChange={handleChange} />;
    }
  };

  return (
    <Container maxWidth="md">
      {conditionalComponent()}
      {formData.consent ? (
        <>
          {step === 2 ? (
            <Button className="mb-5" href="/tls/" variant="contained" color="primary">
              Go back
            </Button>
          ) : (
            <Button className="mb-5" color="primary" variant="contained" style={{ backgroundColor: 'green', color: 'white' }} onClick={handleSubmit}>
              {step === 0 ? 'Next' : 'Submit'}
            </Button>
          )}
        </>
      ) : null}
    </Container>
  );
}
