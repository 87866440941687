import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select'; 


export default class Questionnaire extends React.Component {
  constructor(props){
		super();
		this.state = {
			status: "picsRate",
			['rate_1']: 50,
			['rate_2']: 50,
			['rate_3']: 50,
			['rate_4']: 50,
			['rate_5']: 50,
			bodyAccept: 50,
			bodyDissatisfaction: 50,
			changeAppearance: 50

		}
	}

	prepareImgSrc(id) {
		return "/images/Ankieta/" + id + ".jpg";
	}

	handleChange = (event, newValue) => {
		this.setState({
			[event.target.parentNode.id]:   newValue,
		});
  };

	async handleStep() {
		if(this.state.status === 'picsRate')
		{	
			this.setState({
				status: 'personalRate'
			}) 
		}
			else if(this.state.status === "personalRate" && this.props.firstQuestionaire){
				this.setState({
					status: 'personalInfo'
				})
			}
			else{
				this.props.handleQuestionnaire(this.state);
			}
	}

	generateAgeMenu() {
		let menu = [];
		for (let age=18; age<=90; age++)
			 menu.push(<MenuItem key={age} value={age}>{age}</MenuItem>);
		return menu;
	}
	getCountries(){
		let countriesList = []
		countriesList.push(<MenuItem key="unset" value="unset" default disabled>Country</MenuItem>)
		 fetch('https://restcountries.eu/rest/v2/all')
      .then(response => response.json())
      .then(countries => countries.map(country => countriesList.push(<MenuItem key={country.name} value={country.name}>{country.name}</MenuItem>)));
		return countriesList;
	}

	render() {
		if(this.state.status === "picsRate")	
		return	(
				<Box textAlign="center" className={"questionnaire"}>
					<h5>How do you think, to what degree the photos below present natural appearance?</h5>
					<span><small>(Please, move the slider towards left if you believe that the appearance was completely unaltered or right if you believe that it was completely altered)</small></span><br/><br/>
					{this.props.picsSet.map((pic, index) => (
						<div key={pic}>
							<img src={this.prepareImgSrc(pic)} alt="model" />
							<Box textAlign="center"	id="pictureRate" className="slider">
								<Slider 
									id={"rate_" + (index+1)}
									valueLabelDisplay="auto"
									onChange={
										(e, val) => {this.setState({["rate_"+(index+1)]: val})}
										}
									defaultValue={50}
									track={false}
									marks={[
										{
											value: 0,
											label: 'Completely unaltered',
										},
										{
											value: 50,
											label: "50",
										},
										{
											value: 100,
											label: 'Completely altered',
										},
									]}
									min={0}
									max={100}
								/>
							</Box>
					</div>))}
						<Button variant="contained" onClick={this.handleStep.bind(this)} color="primary">Next</Button>
				</Box>
			)
		else if(this.state.status === "personalRate") {
			return (
				<Box textAlign="center" id="personalRate" className={"questionnaire"}>
					<h5>How much do you agree with the following statements:</h5>
					<br/>
					<br/>
					<span>I am satisfied with my body.</span>
						<Box textAlign="center"	className="slider">
							<Slider 
								id={"bodyDissatisfaction"}
								valueLabelDisplay="auto"
								onChange={(e, val) => {this.setState({bodyDissatisfaction: val})}}
								defaultValue={50}
								track={false}
								marks={[
									{
										value: 0,
										label: 'Completely disagree',
									},
									{
										value: 50,
										label: "50",
									},
									{
										value: 100,
										label: 'Completely agree',
									},
								]}
								min={0}
								max={100}
							/>
						</Box>
						<span>I accept my body.</span>
						<Box textAlign="center"	className="slider">
							<Slider 
								id={"bodyAccept"}
								valueLabelDisplay="auto"
								onChange={(e, val) => {this.setState({bodyAccept: val})}}
								defaultValue={50}
								track={false}
								marks={[
									{
										value: 0,
										label: 'Completely disagree',
									},
									{
										value: 50,
										label: "50",
									},
									{
										value: 100,
										label: 'Completely agree',
									},
								]}
								min={0}
								max={100}
							/>
						</Box>
						<span>I would like to change a lot in my appearance.</span>
						<Box textAlign="center"	className="slider">
							<Slider 
								id={"changeAppearance"}
								valueLabelDisplay="auto"
								onChange={(e, val) => {this.setState({changeAppearance: val})}}
								defaultValue={50}
								track={false}
								marks={[
									{
										value: 0,
										label: 'Completely disagree',
									},
									{
										value: 50,
										label: "50",
									},
									{
										value: 100,
										label: 'Completely agree',
									},
								]}
								min={0}
								max={100}
							/>
						</Box>
						<Button variant="contained" onClick={this.handleStep.bind(this)} color="primary">Next</Button>
					</Box>
			)
		}
		else if(this.state.status === "personalInfo" && this.props.firstQuestionaire) {
			return (
				<Box textAlign="center" id="personalRate" className={"questionnaire"}>
					<Box textAlign="center">
						<span>What is your sex?</span>
						<RadioGroup required row error={this.state.sex===undefined ? true: false}  aria-label="gender" className="radioGroupCenter" name="gender1" onChange={ (e) => this.setState({sex: e.target.value})}>
							<FormControlLabel value="0" control={<Radio />} label="Male" />
							<FormControlLabel value="1" control={<Radio />} label="Female" />
							<FormControlLabel value="2" control={<Radio />} label="Other or prefer not to say" />
						</RadioGroup>
					</Box>
					<br/>
					<Box textAlign="center">
						<span>How old are you?</span><br/>
						<FormControl  required className="ageFormControl" >
							<Select id="age" labelId="age" error={this.state.age===undefined ? true: false} onChange={ (e) => this.setState({age: e.target.value})}>
								{this.generateAgeMenu()}
							</Select>
						</FormControl>
					</Box>
					<br/>
					<Box textAlign="center">
						<span>Where do you come from?</span><br/>
						<FormControl required  className="ageFormControl">
							<Select	id="country" labelId="country"  error={this.state.country===undefined ? true: false} renderValue={() => this.state.country === undefined ? "Country" : this.state.country}  onChange={ (e) => {this.setState({country: e.target.value})}}>
								{this.getCountries()}
							</Select>
						</FormControl>
					</Box>
					<br/>
						<Button variant="contained" disabled={this.state.age===undefined || this.state.country===undefined || this.state.sex===undefined ? true : false} onClick={this.handleStep.bind(this)} color="primary">Next</Button>
					</Box>
			)
		}
	}
}  
