import React, { useState } from 'react';
import '../../App.css';
import Main from '../Main/Main'
import '../../Game.css';

export default function Game() {
  return (
      <Main />
  );
}


