import React, { useState } from 'react';
import '../../App.css';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

export default function About() {
  return (
    <Container maxWidth="md">
      <Box pt={5} pb={3}>
        <Typography variant="h4" className="sectionName">
          <b>About me</b> <hr className="headerDivider" />
        </Typography>
      </Box>
      <Box className="imgContainer" flexWrap="wrap" align="center">
        <img src="about.png" className="aboutMeImg" />
      </Box>
      <Box pt={3}>
        <Typography variant="h5" className="description" align="justify">
          Hi, my name is Marta Kowal. I have a PhD in psychology, currently
          holding a post-doc position at the University of Wrocław, Wrocław,
          Poland. I am generally interested in social psychology and
          cross-cultural studies, while my primary focus lies in research on
          romantic love and mate attraction.
        </Typography>
        <Box pt={3}>
          <Typography variant="h5" className="description" align="">
            <br />
            <br />
            <br />
            <Box pt={5}>
              <Typography variant="h5">
                Grant’s Principal Investigator
              </Typography>
              <Box pt={1} pb={3}>
                <Typography variant="h7" className="" align="justify">
                  <b>2020-2023</b>
                </Typography>
                <br></br>
                <Typography variant="h7" className="" align="justify">
                  <b>
                    Improving one’s physical attractiveness–sex and
                    cross-cultural comparison
                  </b>{' '}
                  (Preludium-17 financed by National Science Centre – Poland;
                  ~44 000 EUR); Reg. No: 2019/33/N/HS6/00054.
                </Typography>
              </Box>
              <Typography variant="h5">Executor of the grants</Typography>
              <Box pt={2}>
                <Typography variant="h7" className="" align="justify">
                  <b>2020-2023</b>
                </Typography>
                <br></br>
                <Typography variant="h7" className="" align="justify">
                  <b>
                    Homo Aestheticus: Testing Evolutionary Aesthetic Hypotheses
                    in Four Tribal
                  </b>{' '}
                  Populations (OPUS-18 financed by National Science Centre –
                  Poland; ~141 000 EUR); Reg. No: 2019/35/B/HS1/02293; Prinicpal
                  Investigator: prof.{' '}
                  <Link
                    target="_blank"
                    rel="noopener"
                    href="https://scholar.google.com/citations?hl=pl&user=Fb4av6oAAAAJ"
                  >
                    Piotr Sorokowski
                  </Link>
                  .
                </Typography>
              </Box>
              <Box pt={2}>
                <Typography variant="h7" className="" align="justify">
                  <b>2018-2020</b>
                </Typography>
                <br></br>
                <Typography variant="h7" className="" align="justify">
                  <b>
                    Parent's Time Perspective and Children's Experience of
                    Postsurgical Pain and Children's Behaviours after Surgery
                  </b>{' '}
                  Parent's Time Perspective and Children's Experience of
                  Postsurgical Pain and Children's Behaviours after Surgery
                  (OPUS-12 financed by National Science Centre – Poland; ~90 000
                  EUR); Reg. No: 2016/23/B/HS6/03888; Prinicpal Investigator:
                  prof.{' '}
                  <Link
                    target="_blank"
                    rel="noopener"
                    href="https://scholar.google.com/citations?user=KRnAj9kAAAAJ&hl=pl&oi=ao"
                  >
                    Małgorzata Sobol-Kwapińska
                  </Link>
                  .
                </Typography>
              </Box>
              <Box pt={2}>
                <Typography variant="h7" className="" align="justify">
                  <b>2018</b>
                </Typography>
                <br></br>
                <Typography variant="h7" className="" align="justify">
                  <b>
                    Comparison of the effectiveness of tactile and visual
                    stimuli as distractions from short-term pain
                  </b>{' '}
                  (MINIATURA financed by National Science Centre – Poland; ~ 2
                  500 EUR); Reg. No: 0213/2054/18; Principal Investigator: dr.{' '}
                  <Link
                    target="_blank"
                    rel="noopener"
                    href="https://scholar.google.com/citations?user=Jp9mRt4AAAAJ&hl=pl&oi=ao"
                  >
                    Marcin Czub
                  </Link>
                  .
                </Typography>
              </Box>
            </Box>
            <Box pt={5}>You can find me on:</Box>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item md={3} xs={12}>
                <Link
                  target="_blank"
                  rel="noopener"
                  href="https://scholar.google.com/citations?user=US0485YAAAAJ"
                >
                  <img className="icon-bottom" src="Scholar.svg" width="30px" />{' '}
                  Scholar
                </Link>
              </Grid>
              <Grid item md={3} xs={12}>
                <Link
                  target="_blank"
                  rel="noopener"
                  href="https://twitter.com/Marta7Kowal"
                >
                  <img className="icon-bottom" src="twitter.png" width="30px" />{' '}
                  Twitter
                </Link>
              </Grid>
              <Grid item md={3} xs={12}>
                <Link
                  target="_blank"
                  rel="noopener"
                  href="https://www.researchgate.net/profile/Marta_Kowal"
                >
                  <img
                    className="icon-bottom"
                    src="researchgate.png"
                    width="30px"
                  />{' '}
                  Research Gate
                </Link>
              </Grid>
              <Grid item md={3} xs={12}>
                <Link
                  target="_blank"
                  rel="noopener"
                  href="mailto:marta7kowal@gmail.com"
                  noWrap
                >
                  <img className="icon-bottom" src="gmail.png" width="30px" />{' '}
                  marta7kowal@gmail.com{' '}
                </Link>
              </Grid>
            </Grid>
            <Box pt={5}>Personal information:</Box>
            <Box>In general, I love: running, nature, and people :-)</Box>
          </Typography>
          <Box pb={5} pt={2}>
            <img src="aboutPic.jpg" width="100%" />
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
