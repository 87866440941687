import React, { useState } from 'react';
import '../../App.css';
import DefaultContent from './tlsComponents/DefaultContent';
import ResearchTLS from './tlsComponents/ResearchComponents/ResearchTLS';
import TestIntroTLS from './tlsComponents/TestComponents/TestIntroTLS';

export default function TLS15() {
  const showResearchContent = () => setContent(<ResearchTLS />);
  const setTest = () => setContent(<TestIntroTLS />);
  const defaultContent = <DefaultContent setResearch={showResearchContent} setTest={setTest} />;
  const [content, setContent] = useState(defaultContent);

  return content;
}
