import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import { Button, Typography } from '@material-ui/core';

export default function TestIntroTLS() {
  return (
    <Container maxWidth="md" className="mt-4">
      <Typography component="p">
        Take the Love Test (TLS-15)! Find out what your level of love is and compare it to the average love level of people in your country (based on
        the study on 118,715 participants from 178 countries worldwide, see{' '}
        <a href="https://doi.org/10.1007/s10508-023-02702-7" target="_blank">
          Kowal et al., 2024
        </a>
        ).
      </Typography>
      <br />
      <Typography component="p">
        The Love Test (TLS-15) is based on Sternberg’s (1988,{' '}
        <a href="https://doi.org/10.1002/(SICI)1099-0992(199705)27:3%3C313::AID-EJSP824%3E3.0.CO;2-4" target="_blank">
          1997
        </a>
        ) Triangular Theory of Love, which is currently one of the most prominent theories of love. It contends that love consists of three
        components: Intimacy, Passion, and Commitment.
      </Typography>
      <br />
      Do the test now!
      <br />
      <div className="mt-4">
        <Button href="/tls/" className="" variant="contained" color="primary">
          Go back
        </Button>
        <Button href="/tls/test/" className="ml-3" variant="contained" style={{ backgroundColor: 'green', color: 'white' }}>
          Take test
        </Button>
      </div>
    </Container>
  );
}
