import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import React from 'react';
import { COUNTRIES } from '../../StaticData';
import { Autocomplete } from '@mui/material';

export default function BasicInfo({ formData, engData, langData, errors, setFormData, handleChange }) {
  const getAgeList = () => {
    let list = [];
    for (let i = 18; i <= 90; i++) {
      i === 90
        ? list.push(
            <MenuItem key="90 and more" value="90 and more">
              90 and more
            </MenuItem>
          )
        : list.push(
            <MenuItem key={i} value={i}>
              {i}
            </MenuItem>
          );
    }
    return list;
  };

  return !formData.consent ? (
    <>
      <FormGroup className="my-5">
        <FormControlLabel
          required
          control={<Checkbox name="consent" checked={formData.consent} onChange={() => setFormData({ ...formData, consent: true })} />}
          label={langData.consent}
        />
      </FormGroup>
      <Button href="/tls/test" className="ml-3" variant="contained" color="primary">
        Go back
      </Button>
    </>
  ) : (
    <>
      <FormControl className="my-3 mt-5" fullWidth component="fieldset" error={errors.includes('gender')}>
        <FormLabel component="legend">{langData.genderQ}</FormLabel>
        <RadioGroup aria-label="gender" name="gender" value={formData['gender']} onChange={handleChange}>
          {langData.genders.map((gender, index) => (
            <FormControlLabel key={gender} value={engData['genders'][index]} control={<Radio />} label={gender} />
          ))}
        </RadioGroup>
      </FormControl>

      <FormControl fullWidth className="my-3" error={errors.includes('age')}>
        <InputLabel id="age">{langData.ageQ}</InputLabel>
        <Select labelId="age" id="age" name="age" value={formData['age']} onChange={handleChange}>
          {getAgeList()}
        </Select>
      </FormControl>

      <Autocomplete
        value={formData['country']}
        name="country"
        onChange={(event, newValue) => {
          setFormData({
            ...formData,
            ['country']: newValue,
          });
        }}
        id="controllable-states-demo"
        options={COUNTRIES.map((option) => option.name)}
        renderInput={(params) => <TextField error={errors.includes('country')} {...params} label={langData.liveQ} />}
      />
      <small className="note">{langData.countryHint}</small>

      <FormControl component="fieldset" fullWidth className="my-3" error={errors.includes('relationshipStatus')}>
        <FormLabel component="legend">{langData.relationShipQ}</FormLabel>
        <RadioGroup aria-label="relationshipStatus" name="relationshipStatus" value={formData['relationshipStatus']} onChange={handleChange}>
          {langData.relationShips.map((relationship, index) => (
            <FormControlLabel key={relationship} value={engData['relationShips'][index]} control={<Radio />} label={relationship} />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
}
