import React from 'react';

export default function ResultCard({ img, className, result, title, description }) {
  return (
    <div className={`tlsCard my-3 ${className}`}>
      <img className="img" src={img} />
      <div className={`result `}>{result}</div>
      <div className="resultContent">
        <div className="title">{title}</div>
        <div className="desc">{description}</div>
      </div>
    </div>
  );
}
