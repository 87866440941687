import React, { memo } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';

const geoUrl = 'https://unpkg.com/world-atlas@2.0.2/countries-110m.json';

const renderColor = (people) => {
  switch (people) {
    case 0:
      return '#D6D6DA';
    default:
      return 'blue';
  }
};

const hoverColor = (people) => {
  switch (people) {
    case 0:
      return '#E42';
    default:
      return 'blue';
  }
};

let list = {
  Afghanistan: { people: 1 },
  Angola: { people: 1 },
  Albania: { people: 1 },
  'United Arab Emirates': { people: 1 },
  Argentina: { people: 1 },
  Armenia: { people: 1 },
  Antarctica: { people: 0 },
  'French Southern and Antarctic Lands': { people: 0 },
  Australia: { people: 1 },
  Austria: { people: 1 },
  Azerbaijan: { people: 1 },
  Burundi: { people: 0 },
  Belgium: { people: 1 },
  Benin: { people: 0 },
  'Burkina Faso': { people: 0 },
  Bangladesh: { people: 1 },
  Bulgaria: { people: 1 },
  'The Bahamas': { people: 0 },
  'Bosnia and Herzegovina': { people: 1 },
  Belarus: { people: 1 },
  Belize: { people: 0 },
  Bolivia: { people: 1 },
  Brazil: { people: 1 },
  Brunei: { people: 1 },
  Bhutan: { people: 0 },
  Botswana: { people: 0 },
  'Central African Republic': { people: 0 },
  Canada: { people: 1 },
  Switzerland: { people: 1 },
  Chile: { people: 1 },
  China: { people: 1 },
  'Ivory Coast': { people: 0 },
  Cameroon: { people: 0 },
  'Democratic Republic of the Congo': { people: 0 },
  'Republic of the Congo': { people: 0 },
  Colombia: { people: 1 },
  'Costa Rica': { people: 0 },
  Cuba: { people: 0 },
  'Northern Cyprus': { people: 0 },
  Cyprus: { people: 1 },
  'Czech Republic': { people: 1 },
  Germany: { people: 1 },
  Djibouti: { people: 0 },
  Denmark: { people: 1 },
  'Dominican Republic': { people: 1 },
  Algeria: { people: 1 },
  Ecuador: { people: 1 },
  Egypt: { people: 1 },
  Eritrea: { people: 0 },
  Spain: { people: 1 },
  Estonia: { people: 1 },
  Ethiopia: { people: 0 },
  Finland: { people: 1 },
  Fiji: { people: 0 },
  'French Guiana': { people: 0 },
  'Falkland Islands': { people: 0 },
  France: { people: 1 },
  Gabon: { people: 0 },
  'United Kingdom': { people: 1 },
  Georgia: { people: 1 },
  Ghana: { people: 1 },
  Guinea: { people: 0 },
  Gambia: { people: 0 },
  'Guinea Bissau': { people: 0 },
  'Eq. Guinea': { people: 0 },
  Greece: { people: 1 },
  Greenland: { people: 0 },
  Guatemala: { people: 1 },
  Guyana: { people: 0 },
  Honduras: { people: 1 },
  Croatia: { people: 1 },
  Haiti: { people: 0 },
  Hungary: { people: 1 },
  Indonesia: { people: 1 },
  India: { people: 1 },
  Ireland: { people: 1 },
  Iran: { people: 1 },
  Iraq: { people: 0 },
  Iceland: { people: 0 },
  Israel: { people: 1 },
  Italy: { people: 1 },
  Jamaica: { people: 1 },
  Jordan: { people: 1 },
  Japan: { people: 1 },
  Kazakhstan: { people: 1 },
  Kenya: { people: 1 },
  Kyrgyzstan: { people: 0 },
  Cambodia: { people: 0 },
  'South Korea': { people: 1 },
  Kosovo: { people: 0 },
  Kuwait: { people: 1 },
  Laos: { people: 0 },
  Lebanon: { people: 1 },
  Liberia: { people: 0 },
  Libya: { people: 0 },
  'Sri Lanka': { people: 1 },
  Lesotho: { people: 0 },
  Lithuania: { people: 1 },
  Luxembourg: { people: 0 },
  Latvia: { people: 0 },
  Morocco: { people: 1 },
  Moldova: { people: 1 },
  Madagascar: { people: 0 },
  Mexico: { people: 1 },
  Macedonia: { people: 1 },
  Mali: { people: 0 },
  Myanmar: { people: 0 },
  Montenegro: { people: 1 },
  Mongolia: { people: 1 },
  Mozambique: { people: 0 },
  Mauritania: { people: 0 },
  Malawi: { people: 0 },
  Malaysia: { people: 1 },
  Namibia: { people: 0 },
  'New Caledonia': { people: 0 },
  Niger: { people: 0 },
  Nigeria: { people: 1 },
  Nicaragua: { people: 0 },
  Netherlands: { people: 1 },
  Norway: { people: 1 },
  Nepal: { people: 1 },
  'New Zealand': { people: 1 },
  Oman: { people: 0 },
  Pakistan: { people: 1 },
  Panama: { people: 0 },
  Peru: { people: 1 },
  Philippines: { people: 1 },
  'Papua New Guinea': { people: 0 },
  Poland: { people: 1 },
  'Puerto Rico': { people: 0 },
  'North Korea': { people: 0 },
  Portugal: { people: 1 },
  Paraguay: { people: 0 },
  Palestine: { people: 0 },
  Qatar: { people: 1 },
  Romania: { people: 1 },
  Russia: { people: 1 },
  Rwanda: { people: 0 },
  'Western Sahara': { people: 0 },
  'Saudi Arabia': { people: 0 },
  Sudan: { people: 0 },
  'South Sudan': { people: 0 },
  Senegal: { people: 0 },
  'Solomon Islands': { people: 0 },
  'Sierra Leone': { people: 0 },
  'El Salvador': { people: 1 },
  Somaliland: { people: 0 },
  Somalia: { people: 0 },
  'Republic of Serbia': { people: 1 },
  Suriname: { people: 0 },
  Slovakia: { people: 1 },
  Slovenia: { people: 1 },
  Sweden: { people: 1 },
  Swaziland: { people: 0 },
  Syria: { people: 0 },
  Chad: { people: 0 },
  Togo: { people: 0 },
  Thailand: { people: 1 },
  Tajikistan: { people: 0 },
  Turkmenistan: { people: 0 },
  'East Timor': { people: 0 },
  'Timor-Leste': { people: 0 },
  'Trinidad and Tobago': { people: 1 },
  Tunisia: { people: 1 },
  Turkey: { people: 1 },
  Taiwan: { people: 1 },
  'United Republic of Tanzania': { people: 0 },
  Uganda: { people: 1 },
  Ukraine: { people: 1 },
  Uruguay: { people: 1 },
  'United States of America': { people: 1 },
  Uzbekistan: { people: 1 },
  Venezuela: { people: 1 },
  Vietnam: { people: 0 },
  Vanuatu: { people: 0 },
  Yemen: { people: 0 },
  'South Africa': { people: 1 },
  Zambia: { people: 0 },
  Zimbabwe: { people: 0 },
};

const MinMap = ({ setTooltipContent }) => {
  return (
    <ComposableMap data-tip="" width={50} height={30} projectionConfig={{ scale: 10 }}>
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              onMouseEnter={() => {
                const { name } = geo.properties;
                setTooltipContent(`${name}`);
              }}
              onMouseLeave={() => {
                setTooltipContent('');
              }}
              style={{
                default: {
                  fill: renderColor(list[geo.properties.name]?.people),
                  outline: 'none',
                },
                hover: {
                  fill: hoverColor(list[geo.properties.name]?.people),
                  outline: 'none',
                },
                pressed: {
                  fill: '#E42',
                  outline: 'none',
                },
              }}
            />
          ))
        }
      </Geographies>
    </ComposableMap>
  );
};
export default memo(MinMap);
