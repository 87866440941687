import { GoogleSpreadsheet } from 'google-spreadsheet';

const SPREADSHEET_ID = '1VZgIrLiGtGony0vhEU_MDQ046MHG-dFuENObum8TyY8';
const CLIENT_EMAIL = 'marta-website@martawebsite.iam.gserviceaccount.com';
const PRIVATE_KEY =
  '-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC+s079q0os2EZm\n9MRhpBzvmrr83ZFUJh1GZPbuXgYr0LOyzW/6HRmX9FSo7i+cB8CK7GMklxyPK5vI\nrbAcWJhFnN3DarwP54CeKgcVwXHhYkn7ZaU1MjgIBZYZDVtBdaURwGUhtyadS+GY\nJ4PcwUS5swN8IJGUfkoXfljSuWDQ081Qj1m0xjaFjlSQrjDnGM1dnfeFRI7wAd30\nvGmwK2h1hx7QnJjYgO+12fcz7fWACbAv7Z9n+/Jq/M2cBI5YpIQTqrs6n6VWMcrO\nIKM9h+FmBX9S0qKe+r7lXeBRroWWVjVMgp8vrz7NdRSsdTWGTNxQvpFWH4H7VnF6\nDWTYue9/AgMBAAECggEAHHabTSh3tJ7T+e0Bsh3dMHQqAbEOeSlhMuRsqOJsIwu5\nA/CUN0LdqD0QinsBVyrJtdKXmlqwVBGVucvBiVGTP3ICSvck5RjQLTX9AxBxr3V3\nW73C/WAmqyfY90SeQMnO9AnWmRmC+spcfH81YsnkDfWRXmxms5Zv96Fk9OWKH6ZN\neF4eDgsOc1C1ij4nXiknZ3EzCKp9jDJu79x/VqlGZRHDpMVE5F225DlabuZ4TDJ2\nBHkvjuOM3KeOeyeEY+cGE/+szdJzI7CFp57UEkzmFKLsIFJmkWvCqmHgyzYgF2Zw\nM9Mrbf+b+CHP3Wuu0wyLc6l4k4wJ3QCWh2p0HG81VQKBgQD89cs3a8PO6a8a22Sr\nPd8mNAWwKo1KbnwwVxvG0FZsU/BK4/mY51v3uFoVjxxd+caxNjDSB2M1AqJfSdd0\nz/yLngC6ugF5zqdjR7sgLH2zqnCwFp2U6LYDBhvUonSIHmN0OdHNH2RJi5pDw3eb\n8Jh8fC5x3R6k/3vk5BQU2O3rgwKBgQDA/fqkL1dr19xBIznxcTkfnb5EOeEhlalG\nok4Z0lMlGOIXBprekycpC4H3dH4BYJTy+Tetr8u9koemm/X8pKcQVNE3MOqQZ/ln\nZQ89He8hNGQHV/ys60KtXGXNvEd7Zw3bHE/XfV41JtWn9aW7tcNZNUDtf62mj2pk\nhta7May/VQKBgC8puLDlk8VxRmeQfi17RK6/xku5M0ysWtU8eH5R+7sIz2V3KeM7\nR6dhJNKeMyDGYChMPT/7hqCslA+GGfi6ipCRbftikMHgKM9W4Wm3QdyMq6P5I3pP\nBRh9PBUiEO4MFGjoGtjOwIkB0UpPGG7npdaX5KTwIDWNONFNQP0+EOX3AoGAbqfx\ns3w1iu89KdgR3B9IWpluw93P+Rq/8XEUiPewlr+6uusdl2sWdBFWFtWjfhSrstk0\nlJEMqAjBYMVh+NdLtzB6uUOB6wTC2wb2AtwMBaTticxoY5mNJKuq75j5/4aqSizk\nlrEaeC1pKiHOQl/Iv3iqXsLrhWoL3dH2g2TLIPUCgYADiMD8LTf55gfmZ04pp/9H\n9L62WaVaBdlqCAIdZ4aYJMw3uJRteAKDnoD+La/SS6DWlD1GbkwgJdXkOEx8tYLF\nszxqIJaxXI4hax5E/QTdtWvUTSBVPLyscAIgRQC7QX2W5wz116uuijV73l/UULN7\nqPhvZys7FFo0kvpUM5QFwg==\n-----END PRIVATE KEY-----\n';

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
const appendSpreadsheet = async (sheetID, row) => {
  try {
    await doc.useServiceAccountAuth({
      client_email: CLIENT_EMAIL,
      private_key: PRIVATE_KEY,
    });
    await doc.loadInfo();
    const sheet = doc.sheetsById[sheetID];
    const result = await sheet.addRow(row);
  } catch (e) {
    console.error('Error: ', e);
  }
};

export { appendSpreadsheet };
