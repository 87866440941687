const LANGUAGES = [
  { id: 0, language: 'Arabic', code: 'SAU', urlCode: 'SAU', lang: 'ar' },
  { id: 1, language: 'Bosnian', code: 'BIH', urlCode: 'BIH', lang: 'bs' },
  { id: 2, language: 'Bulgarian', code: 'BGR', urlCode: 'BGR', lang: 'bg' },
  { id: 3, language: 'Chinese Traditional', code: 'CHN', urlCode: 'CHN', lang: 'zh-t' },
  { id: 4, language: 'Croatian', code: 'HRV', urlCode: 'HRV', lang: 'hr' },
  { id: 5, language: 'Czech', code: 'CZE', urlCode: 'CZE', lang: 'cs' },
  { id: 6, language: 'Dutch', code: 'NLD', urlCode: 'NLD', lang: 'nl' },
  { id: 7, language: 'English', code: 'USA', urlCode: 'ENG', lang: 'eng' },
  { id: 8, language: 'Estonian', code: 'EST', urlCode: 'EST', lang: 'et' },
  { id: 9, language: 'Farsi', code: 'IRN', urlCode: 'IRN', lang: 'far' },
  { id: 10, language: 'Finnish', code: 'FIN', urlCode: 'FIN', lang: 'fi' },
  { id: 11, language: 'French', code: 'FRA', urlCode: 'FRA', lang: 'fr' },
  { id: 12, language: 'Georgian', code: 'GEO', urlCode: 'GEO', lang: 'kat' },
  { id: 13, language: 'German', code: 'DEU', urlCode: 'DEU', lang: 'de' },
  { id: 14, language: 'Greek', code: 'GRC', urlCode: 'GRC', lang: 'el' },
  { id: 15, language: 'Hebrew', code: 'ISR', urlCode: 'ISR', lang: 'he' },
  { id: 16, language: 'Hungarian', code: 'HUN', urlCode: 'HUN', lang: 'hu' },
  { id: 17, language: 'Italian', code: 'ITA', urlCode: 'ITA', lang: 'it' },
  { id: 18, language: 'Japanese', code: 'JPN', urlCode: 'JPN', lang: 'ja' },
  { id: 19, language: 'Korean', code: 'PRK', urlCode: 'PRK', lang: 'ko' },
  { id: 20, language: 'Lithuanian', code: 'LTU', urlCode: 'LTU', lang: 'lt' },
  { id: 21, language: 'Macedonian', code: 'MKD', urlCode: 'MKD', lang: 'mk' },
  { id: 22, language: 'Malaysian', code: 'MYS', urlCode: 'MYS', lang: 'ms' },
  { id: 23, language: 'Norwegian', code: 'NOR', urlCode: 'NOR', lang: 'no' },
  { id: 24, language: 'Polish', code: 'POL', urlCode: 'POL', lang: 'pl' },
  { id: 25, language: 'Portuguese (Brazilian)', code: 'BRA', urlCode: 'BRA', lang: 'bpt' },
  { id: 26, language: 'Portuguese (European)', code: 'PRT', urlCode: 'PRT', lang: 'pt' },
  { id: 27, language: 'Romanian', code: 'ROU', urlCode: 'ROU', lang: 'ro' },
  { id: 28, language: 'Russian', code: 'RUS', urlCode: 'RUS', lang: 'ru' },
  { id: 29, language: 'Serbian', code: 'SRB', urlCode: 'SRB', lang: 'sr' },
  { id: 30, language: 'Slovak', code: 'SVK', urlCode: 'SVK', lang: 'sk' },
  { id: 31, language: 'Slovenian', code: 'SVN', urlCode: 'SVN', lang: 'sl' },
  { id: 32, language: 'Spanish', code: 'ES', urlCode: 'ES', lang: 'es-es' },
  { id: 33, language: 'Spanish (Latin)', code: 'ESP', urlCode: 'ESP', lang: 'es' },
  { id: 34, language: 'Swedish', code: 'SWE', urlCode: 'SWE', lang: 'sv' },
  { id: 35, language: 'Turkish', code: 'TUR', urlCode: 'TUR', lang: 'tr' },
  { id: 36, language: 'Ukrainian', code: 'UKR', urlCode: 'UKR', lang: 'uk' },
];
const REFS = [
  {
    reference: 'Kowal, M., et al., (2024). Validation of the Short Version (TLS-15) of the Triangular Love Scale (TLS-45) across 37 Languages.',
    journal: 'Archives of Sexual Behavior, 53',
    issue: '',
    pages: '839-857',
    doi: 'https://doi.org/10.1007/s10508-023-02702-7',
  },
  {
    reference: 'Sternberg, R. J. (1997). Construct validation of a triangular love scale.',
    journal: 'European Journal of Social Psychology, 27',
    issue: '(3)',
    pages: '313-335',
    doi: 'https://doi.org/10.1002/(SICI)1099-0992(199705)27:3%3C313::AID-EJSP824%3E3.0.CO;2-4',
  },
  {
    type: 'book',
    authors: 'Sternberg, R. J.',
    year: 1988,
    title: 'The triangle of love: Intimacy, passion, commitment',
    publisher: 'Basic Books',
  },
];

const COUNTRIES = [
  { name: 'Other', code: 'OTH', tls: 4.0, intimacy: 4.1, passion: 3.8, commitment: 4.1 },
  { name: 'Afghanistan', code: 'AF', tls: 4.41, intimacy: 4.38, passion: 4.2, commitment: 4.64 },
  { name: 'Albania', code: 'AL', tls: 4.09, intimacy: 4.1, passion: 4.01, commitment: 4.16 },
  { name: 'Algeria', code: 'DZ', tls: 3.58, intimacy: 3.62, passion: 3.42, commitment: 3.7 },
  { name: 'Andorra', code: 'AD', tls: 4.06, intimacy: 4.08, passion: 3.98, commitment: 4.12 },
  { name: 'Angola', code: 'AO', tls: 4.07, intimacy: 4.21, passion: 3.79, commitment: 4.19 },
  { name: 'Antigua and Barbuda', code: 'AG', tls: 4.27, intimacy: 4.68, passion: 4.24, commitment: 3.88 },
  { name: 'Argentina', code: 'AR', tls: 3.84, intimacy: 4.01, passion: 3.39, commitment: 4.11 },
  { name: 'Armenia', code: 'AM', tls: 4.48, intimacy: 4.47, passion: 4.62, commitment: 4.36 },
  { name: 'Australia', code: 'AU', tls: 4.19, intimacy: 4.38, passion: 3.81, commitment: 4.38 },
  { name: 'Austria', code: 'AT', tls: 4.24, intimacy: 4.42, passion: 3.86, commitment: 4.43 },
  { name: 'Azerbaijan', code: 'AZ', tls: 3.84, intimacy: 3.96, passion: 3.76, commitment: 3.8 },
  { name: 'Bahamas', code: 'BS', tls: 2.86, intimacy: 2.93, passion: 2.77, commitment: 2.87 },
  { name: 'Bahrain', code: 'BH', tls: 3.92, intimacy: 3.98, passion: 3.7, commitment: 4.06 },
  { name: 'Bangladesh', code: 'BD', tls: 4.26, intimacy: 4.29, passion: 3.95, commitment: 4.52 },
  { name: 'Barbados', code: 'BB', tls: 4.03, intimacy: 4.1, passion: 3.75, commitment: 4.25 },
  { name: 'Belarus', code: 'BY', tls: 4.04, intimacy: 4.19, passion: 3.73, commitment: 4.19 },
  { name: 'Belgium', code: 'BE', tls: 3.99, intimacy: 4.18, passion: 3.57, commitment: 4.22 },
  { name: 'Belize', code: 'BZ', tls: 3.63, intimacy: 3.92, passion: 3.25, commitment: 3.72 },
  { name: 'Benin', code: 'BJ', tls: 3.92, intimacy: 4.1, passion: 3.6, commitment: 4 },
  { name: 'Bhutan', code: 'BT', tls: 3.44, intimacy: 3.4, passion: 3.53, commitment: 3.4 },
  { name: 'Bolivia', code: 'BO', tls: 3.83, intimacy: 3.99, passion: 3.67, commitment: 3.83 },
  { name: 'Bosnia and Herzegovina', code: 'BA', tls: 4.24, intimacy: 4.37, passion: 3.96, commitment: 4.39 },
  { name: 'Botswana', code: 'BW', tls: 3.38, intimacy: 2.87, passion: 3.67, commitment: 3.6 },
  { name: 'Brazil', code: 'BR', tls: 4.1, intimacy: 4.32, passion: 3.79, commitment: 4.18 },
  { name: 'Brunei Darussalam', code: 'BN', tls: 4.18, intimacy: 4.31, passion: 3.92, commitment: 4.32 },
  { name: 'Bulgaria', code: 'BG', tls: 4.1, intimacy: 4.26, passion: 3.79, commitment: 4.26 },
  { name: 'Burkina Faso', code: 'BF', tls: 3.13, intimacy: 3, passion: 3, commitment: 3.4 },
  { name: 'Cambodia', code: 'KH', tls: 4.5, intimacy: 4.5, passion: 4.5, commitment: 4.5 },
  { name: 'Cameroon', code: 'CM', tls: 3.46, intimacy: 3.3, passion: 3.8, commitment: 3.25 },
  { name: 'Canada', code: 'CA', tls: 4.17, intimacy: 4.33, passion: 3.78, commitment: 4.39 },
  { name: 'Cape Verde', code: 'CV', tls: 4, intimacy: 4.33, passion: 3.6, commitment: 4.07 },
  { name: 'Chad', code: 'TD', tls: 3.46, intimacy: 3.4, passion: 4, commitment: 3.2 },
  { name: 'Chile', code: 'CL', tls: 4.04, intimacy: 4.29, passion: 3.65, commitment: 4.18 },
  { name: 'China', code: 'CN', tls: 3.76, intimacy: 4.05, passion: 3.47, commitment: 3.73 },
  { name: 'Colombia', code: 'CO', tls: 4.17, intimacy: 4.31, passion: 3.95, commitment: 4.24 },
  { name: 'Congo', code: 'CG', tls: 4.63, intimacy: 4.9, passion: 4.1, commitment: 4.9 },
  { name: 'Congo, The Democratic Republic of the', code: 'CD', tls: 4.7, intimacy: 5, passion: 4.2, commitment: 4.92 },
  { name: 'Costa Rica', code: 'CR', tls: 4.11, intimacy: 4.27, passion: 3.87, commitment: 4.2 },
  { name: 'Croatia', code: 'HR', tls: 4.33, intimacy: 4.46, passion: 4.08, commitment: 4.46 },
  { name: 'Cyprus', code: 'CY', tls: 4.05, intimacy: 4.16, passion: 3.73, commitment: 4.25 },
  { name: 'Czech Republic', code: 'CZ', tls: 4.12, intimacy: 4.29, passion: 3.71, commitment: 4.37 },
  { name: 'Denmark', code: 'DK', tls: 4.26, intimacy: 4.44, passion: 3.86, commitment: 4.46 },
  { name: 'Djibouti', code: 'DJ', tls: 3.79, intimacy: 3.62, passion: 4.07, commitment: 3.67 },
  { name: 'Dominica', code: 'DM', tls: 3.73, intimacy: 3.6, passion: 3.8, commitment: 3.8 },
  { name: 'Dominican Republic', code: 'DO', tls: 4.12, intimacy: 4.23, passion: 3.88, commitment: 4.25 },
  { name: 'Ecuador', code: 'EC', tls: 4.03, intimacy: 4.14, passion: 3.84, commitment: 4.1 },
  { name: 'Egypt', code: 'EG', tls: 3.96, intimacy: 4.03, passion: 3.73, commitment: 4.12 },
  { name: 'El Salvador', code: 'SV', tls: 4.22, intimacy: 4.39, passion: 3.97, commitment: 4.31 },
  { name: 'Estonia', code: 'EE', tls: 3.87, intimacy: 4.05, passion: 3.5, commitment: 4.05 },
  { name: 'Ethiopia', code: 'ET', tls: 3.09, intimacy: 3.32, passion: 2.63, commitment: 3.34 },
  { name: 'Fiji', code: 'FJ', tls: 4.27, intimacy: 4.3, passion: 4.1, commitment: 4.4 },
  { name: 'Finland', code: 'FI', tls: 4.13, intimacy: 4.38, passion: 3.68, commitment: 4.34 },
  { name: 'France', code: 'FR', tls: 3.92, intimacy: 4.12, passion: 3.59, commitment: 4.03 },
  { name: 'Gambia', code: 'GM', tls: 3.37, intimacy: 3.8, passion: 2.7, commitment: 3.6 },
  { name: 'Georgia', code: 'GE', tls: 4.06, intimacy: 4.25, passion: 3.94, commitment: 3.98 },
  { name: 'Germany', code: 'DE', tls: 4.05, intimacy: 4.24, passion: 3.7, commitment: 4.21 },
  { name: 'Ghana', code: 'GH', tls: 4.77, intimacy: 4.76, passion: 4.76, commitment: 4.79 },
  { name: 'Greece', code: 'GR', tls: 3.93, intimacy: 4.17, passion: 3.64, commitment: 3.97 },
  { name: 'Guatemala', code: 'GT', tls: 3.85, intimacy: 4.01, passion: 3.5, commitment: 4.04 },
  { name: 'Haiti', code: 'HT', tls: 3.8, intimacy: 3.4, passion: 3.5, commitment: 4.5 },
  { name: 'Honduras', code: 'HN', tls: 4.15, intimacy: 4.25, passion: 3.97, commitment: 4.22 },
  { name: 'Hong Kong', code: 'HK', tls: 3.76, intimacy: 3.87, passion: 3.73, commitment: 3.67 },
  { name: 'Hungary', code: 'HU', tls: 4.29, intimacy: 4.5, passion: 3.97, commitment: 4.41 },
  { name: 'Iceland', code: 'IS', tls: 3.76, intimacy: 3.68, passion: 3.52, commitment: 4.08 },
  { name: 'India', code: 'IN', tls: 4.09, intimacy: 4.17, passion: 3.88, commitment: 4.21 },
  { name: 'Indonesia', code: 'ID', tls: 4.04, intimacy: 4.18, passion: 3.73, commitment: 4.2 },
  { name: 'Iran, Islamic Republic Of', code: 'IR', tls: 4.12, intimacy: 4.22, passion: 3.86, commitment: 4.3 },
  { name: 'Iraq', code: 'IQ', tls: 3.88, intimacy: 3.65, passion: 4, commitment: 4 },
  { name: 'Ireland', code: 'IE', tls: 4.2, intimacy: 4.39, passion: 3.75, commitment: 4.47 },
  { name: 'Israel', code: 'IL', tls: 4.18, intimacy: 4.3, passion: 3.86, commitment: 4.39 },
  { name: 'Italy', code: 'IT', tls: 4.07, intimacy: 4.2, passion: 3.76, commitment: 4.27 },
  { name: 'Jamaica', code: 'JM', tls: 3.95, intimacy: 4.05, passion: 3.57, commitment: 4.23 },
  { name: 'Japan', code: 'JP', tls: 3.57, intimacy: 3.87, passion: 3.17, commitment: 3.66 },
  { name: 'Jordan', code: 'JO', tls: 4.25, intimacy: 4.36, passion: 3.99, commitment: 4.4 },
  { name: 'Kazakhstan', code: 'KZ', tls: 4.11, intimacy: 4.17, passion: 3.82, commitment: 4.34 },
  { name: 'Kenya', code: 'KE', tls: 4.07, intimacy: 4.15, passion: 3.97, commitment: 4.11 },
  { name: 'Kosovo', code: 'XK', tls: 4.11, intimacy: 4.24, passion: 3.84, commitment: 4.4 },
  { name: 'Korea, Republic of', code: 'KR', tls: 3.67, intimacy: 3.94, passion: 3.33, commitment: 3.73 },
  { name: 'Kuwait', code: 'KW', tls: 4.17, intimacy: 4.23, passion: 3.91, commitment: 4.36 },
  { name: 'Kyrgyzstan', code: 'KG', tls: 3.75, intimacy: 3.55, passion: 3.47, commitment: 4.2 },
  { name: 'Latvia', code: 'LV', tls: 4.33, intimacy: 4.49, passion: 4.19, commitment: 4.29 },
  { name: 'Lebanon', code: 'LB', tls: 4.18, intimacy: 4.3, passion: 3.96, commitment: 4.26 },
  { name: 'Liechtenstein', code: 'LI', tls: 4.36, intimacy: 4.53, passion: 3.73, commitment: 4.8 },
  { name: 'Lithuania', code: 'LT', tls: 4.14, intimacy: 4.25, passion: 3.81, commitment: 4.38 },
  { name: 'Luxembourg', code: 'LU', tls: 3.59, intimacy: 3.92, passion: 3.03, commitment: 3.78 },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK', tls: 4.23, intimacy: 4.43, passion: 4.06, commitment: 4.2 },
  { name: 'Madagascar', code: 'MG', tls: 3.4, intimacy: 3.5, passion: 3.5, commitment: 3.2 },
  { name: 'Malawi', code: 'MW', tls: 5, intimacy: 5, passion: 5, commitment: 5 },
  { name: 'Malaysia', code: 'MY', tls: 4.08, intimacy: 4.18, passion: 3.88, commitment: 4.18 },
  { name: 'Malta', code: 'MT', tls: 4.12, intimacy: 4.35, passion: 3.65, commitment: 4.35 },
  { name: 'Marshall Islands', code: 'MH', tls: 1.67, intimacy: 2.2, passion: 1.4, commitment: 1.4 },
  { name: 'Mauritius', code: 'MU', tls: 4.07, intimacy: 3.6, passion: 3.6, commitment: 5 },
  { name: 'Mexico', code: 'MX', tls: 4, intimacy: 4.19, passion: 3.67, commitment: 4.15 },
  { name: 'Micronesia, Federated States of', code: 'FM', tls: 3.83, intimacy: 3.9, passion: 4.3, commitment: 3.3 },
  { name: 'Moldova, Republic of', code: 'MD', tls: 4.27, intimacy: 4.37, passion: 4, commitment: 4.46 },
  { name: 'Mongolia', code: 'MN', tls: 4.47, intimacy: 4.7, passion: 4.1, commitment: 4.6 },
  { name: 'Morocco', code: 'MA', tls: 3.59, intimacy: 3.67, passion: 3.55, commitment: 3.55 },
  { name: 'Mozambique', code: 'MZ', tls: 3, intimacy: 2.75, passion: 3.5, commitment: 2.75 },
  { name: 'Namibia', code: 'NA', tls: 4.53, intimacy: 4.6, passion: 4, commitment: 5 },
  { name: 'Nepal', code: 'NP', tls: 3.92, intimacy: 3.9, passion: 3.84, commitment: 4.01 },
  { name: 'Netherlands', code: 'NL', tls: 4.17, intimacy: 4.31, passion: 3.83, commitment: 4.36 },
  { name: 'New Zealand', code: 'NZ', tls: 4.15, intimacy: 4.27, passion: 3.78, commitment: 4.4 },
  { name: 'Nicaragua', code: 'NI', tls: 3.6, intimacy: 3.87, passion: 3.47, commitment: 3.47 },
  { name: 'Nigeria', code: 'NG', tls: 4.22, intimacy: 4.25, passion: 4.16, commitment: 4.26 },
  { name: 'Norway', code: 'NO', tls: 4.05, intimacy: 4.23, passion: 3.57, commitment: 4.36 },
  { name: 'Oman', code: 'OM', tls: 3.59, intimacy: 3.54, passion: 3.38, commitment: 3.83 },
  { name: 'Pakistan', code: 'PK', tls: 4.16, intimacy: 4.15, passion: 3.98, commitment: 4.34 },
  { name: 'Palestinian Territory, Occupied', code: 'PS', tls: 4.16, intimacy: 4.4, passion: 3.6, commitment: 4.48 },
  { name: 'Panama', code: 'PA', tls: 3.88, intimacy: 4, passion: 3.6, commitment: 4.05 },
  { name: 'Paraguay', code: 'PY', tls: 4.02, intimacy: 4.3, passion: 3.75, commitment: 4 },
  { name: 'Peru', code: 'PE', tls: 4.11, intimacy: 4.22, passion: 3.85, commitment: 4.26 },
  { name: 'Philippines', code: 'PH', tls: 4.38, intimacy: 4.44, passion: 4.22, commitment: 4.47 },
  { name: 'Poland', code: 'PL', tls: 4.22, intimacy: 4.38, passion: 3.91, commitment: 4.36 },
  { name: 'Portugal', code: 'PT', tls: 4.2, intimacy: 4.35, passion: 4, commitment: 4.26 },
  { name: 'Qatar', code: 'QA', tls: 4.1, intimacy: 4.23, passion: 3.69, commitment: 4.38 },
  { name: 'Romania', code: 'RO', tls: 4.23, intimacy: 4.33, passion: 4.04, commitment: 4.32 },
  { name: 'Russian Federation', code: 'RU', tls: 4.17, intimacy: 4.24, passion: 3.84, commitment: 4.42 },
  { name: 'Rwanda', code: 'RW', tls: 4.37, intimacy: 4.4, passion: 4, commitment: 4.7 },
  { name: 'Saudi Arabia', code: 'SA', tls: 3.81, intimacy: 3.85, passion: 3.53, commitment: 4.04 },
  { name: 'Senegal', code: 'SN', tls: 3.22, intimacy: 3.53, passion: 3, commitment: 3.13 },
  { name: 'Serbia', code: 'CS', tls: 4.28, intimacy: 4.44, passion: 4, commitment: 4.41 },
  { name: 'Montenegro', code: 'ME', tls: 4.54, intimacy: 4.78, passion: 4.12, commitment: 4.73 },
  { name: 'Seychelles', code: 'SC', tls: 4.65, intimacy: 4.7, passion: 4.75, commitment: 4.5 },
  { name: 'Singapore', code: 'SG', tls: 4.36, intimacy: 4.6, passion: 3.78, commitment: 4.7 },
  { name: 'Slovakia', code: 'SK', tls: 4.29, intimacy: 4.4, passion: 4.03, commitment: 4.45 },
  { name: 'Slovenia', code: 'SI', tls: 3.96, intimacy: 4.14, passion: 3.52, commitment: 4.21 },
  { name: 'Somalia', code: 'SO', tls: 4.87, intimacy: 5, passion: 4.8, commitment: 4.8 },
  { name: 'South Africa', code: 'ZA', tls: 4.35, intimacy: 4.51, passion: 4.08, commitment: 4.47 },
  { name: 'Spain', code: 'ES', tls: 3.98, intimacy: 4.19, passion: 3.54, commitment: 4.2 },
  { name: 'Sri Lanka', code: 'LK', tls: 4.22, intimacy: 4.3, passion: 4.07, commitment: 4.29 },
  { name: 'Sudan', code: 'SD', tls: 3.6, intimacy: 4.4, passion: 4, commitment: 2.4 },
  { name: 'Sweden', code: 'SE', tls: 4.07, intimacy: 4.26, passion: 3.66, commitment: 4.28 },
  { name: 'Switzerland', code: 'CH', tls: 4.1, intimacy: 4.34, passion: 3.63, commitment: 4.31 },
  { name: 'Syrian Arab Republic', code: 'SY', tls: 3.6, intimacy: 4, passion: 2.8, commitment: 4 },
  { name: 'Taiwan, Province of China', code: 'TW', tls: 3.83, intimacy: 4.11, passion: 3.43, commitment: 3.96 },
  { name: 'Tajikistan', code: 'TJ', tls: 4.11, intimacy: 4, passion: 3.85, commitment: 4.52 },
  { name: 'Tanzania, United Republic of', code: 'TZ', tls: 5, intimacy: 5, passion: 5, commitment: 5 },
  { name: 'Thailand', code: 'TH', tls: 3.83, intimacy: 4.1, passion: 3.57, commitment: 3.83 },
  { name: 'Trinidad and Tobago', code: 'TT', tls: 4.24, intimacy: 4.3, passion: 4.02, commitment: 4.41 },
  { name: 'Tunisia', code: 'TN', tls: 3.36, intimacy: 3.35, passion: 3.36, commitment: 3.38 },
  { name: 'Turkey', code: 'TR', tls: 3.96, intimacy: 4.1, passion: 3.7, commitment: 4.09 },
  { name: 'Turkmenistan', code: 'TM', tls: 4.1, intimacy: 3.8, passion: 4.1, commitment: 4.4 },
  { name: 'Uganda', code: 'UG', tls: 4.15, intimacy: 4.19, passion: 4.12, commitment: 4.14 },
  { name: 'Ukraine', code: 'UA', tls: 4.16, intimacy: 4.26, passion: 3.87, commitment: 4.36 },
  { name: 'United Arab Emirates', code: 'AE', tls: 4.15, intimacy: 4.16, passion: 3.93, commitment: 4.35 },
  { name: 'United Kingdom', code: 'GB', tls: 4.23, intimacy: 4.41, passion: 3.85, commitment: 4.41 },
  { name: 'United States', code: 'US', tls: 4.23, intimacy: 4.36, passion: 3.92, commitment: 4.39 },
  { name: 'Uruguay', code: 'UY', tls: 3.95, intimacy: 4.22, passion: 3.48, commitment: 4.13 },
  { name: 'Uzbekistan', code: 'UZ', tls: 4.07, intimacy: 4.13, passion: 3.76, commitment: 4.31 },
  { name: 'Vanuatu', code: 'VU', tls: 2.13, intimacy: 2.2, passion: 1.6, commitment: 2.6 },
  { name: 'Venezuela', code: 'VE', tls: 4.14, intimacy: 4.28, passion: 3.9, commitment: 4.24 },
  { name: 'Viet Nam', code: 'VN', tls: 3.86, intimacy: 3.93, passion: 3.67, commitment: 4 },
  { name: 'Yemen', code: 'YE', tls: 3.4, intimacy: 3.2, passion: 3, commitment: 4 },
  { name: 'Zambia', code: 'ZM', tls: 4.57, intimacy: 4.8, passion: 4, commitment: 4.9 },
  { name: 'Zimbabwe', code: 'ZW', tls: 3.88, intimacy: 3.96, passion: 3.65, commitment: 4.02 },
];

export { LANGUAGES, REFS, COUNTRIES };
