import React from 'react';
import { COUNTRIES, REFS } from '../../StaticData';
import { useEffect } from 'react';
import { appendSpreadsheet } from '../../../../Helpers/GoogleSpreadSheet';
import ResultCard from './ResultCard';
import CountUp from 'react-countup';
import Ref from '../../../../reference/Ref';
import BookRef from '../../../../reference/BookRef';

export default function Results({ formData }) {
  const calculateMean = (start, end) => {
    let sum = 0;
    for (let i = start; i <= end; i++) {
      sum += parseInt(formData[`tls${i}`]);
    }
    return (sum / (1 + end - start)).toFixed(1);
  };

  const userTlsMean = calculateMean(1, 15);
  const userIntimacyMean = calculateMean(1, 5);
  const userPassionMean = calculateMean(6, 10);
  const userCommitmentMean = calculateMean(11, 15);

  const { tls, intimacy, passion, commitment } = COUNTRIES.find((c) => c.name === formData.country);

  const getResultValue = (userMean, mean) => {
    return userMean > mean ? 'above' : userMean < mean ? 'below' : 'the same as';
  };

  const tlsDescription = (
    <>
      A composite score of the Triangular Love Scale (TLS-15). <br />
      <br />
      Your mean level of Love is: <b>{userTlsMean}</b> on a 1-5 scale, with higher values indicating more intense love feelings. <br />
      It is <b>{getResultValue(userTlsMean, tls)}</b> the mean love level{' '}
      {formData.country.toLowerCase() === 'other' ? `across 155 countries` : `in ${formData.country}`} (<b>{tls}</b>)
    </>
  );
  const intimacyDescription = (
    <>
      Your level of Intimacy is: <b>{userIntimacyMean}</b> on a 1-5 scale, with higher values indicating more intense love feelings. It is{' '}
      <b>{getResultValue(userIntimacyMean, intimacy)}</b> the mean Intimacy level{' '}
      {formData.country.toLowerCase() === 'other' ? `across 155 countries` : `in ${formData.country}`} (<b>{intimacy}</b>).
      <br />
      <br />
      Intimacy is associated with feelings of warmth, communication, and connectedness. High intimacy toward one’s partner implies that the
      relationship is close, caring, reflective of good communication, and demonstrates feelings of connectedness.
    </>
  );
  const passionDescription = (
    <>
      Your level of Passion is: <b>{userPassionMean}</b> on a 1-5 scale, with higher values indicating more intense love feelings. It is{' '}
      <b>{getResultValue(userPassionMean, passion)}</b> the mean Passion level{' '}
      {formData.country.toLowerCase() === 'other' ? `across 155 countries` : `in ${formData.country}`} (<b>{passion}</b>). <br />
      <br />
      Passion refers to feelings of excitement, desire, attraction, and physical arousal felt in the presence of a loved one. Passion is often
      referred to as the 'hot' component od love. High passion can include the desire to merge into one with the loved one.
    </>
  );
  const commtimentDescription = (
    <>
      Your level of Commitment is: <b>{userCommitmentMean}</b> on a 1-5 scale, with higher values indicating more intense love feelings. It is{' '}
      <b>{getResultValue(userCommitmentMean, commitment)}</b> the mean Commitment level{' '}
      {formData.country.toLowerCase() === 'other' ? `across 155 countries` : `in ${formData.country}`} (<b>{commitment}</b>). <br />
      <br />
      Commitment, the most cognitive component of love, pertains to one’s conscious decision and motivation to maintain the relationship. Commitment
      is often treated as a relatively “cold” component of love. High commitment refers to one’s belief that the given relationship can last long into
      the future.
    </>
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    appendSpreadsheet(392576668, {
      Lang: formData.lang,
      Gender: formData.gender,
      Age: formData.age,
      Country: formData.country,
      RelationshipStatus: formData.relationshipStatus,
      Tls1: formData.tls1,
      Tls2: formData.tls2,
      Tls3: formData.tls3,
      Tls4: formData.tls4,
      Tls5: formData.tls5,
      Tls6: formData.tls6,
      Tls7: formData.tls7,
      Tls8: formData.tls8,
      Tls9: formData.tls9,
      Tls10: formData.tls10,
      Tls11: formData.tls11,
      Tls12: formData.tls12,
      Tls13: formData.tls13,
      Tls14: formData.tls14,
      Tls15: formData.tls15,
      MeanTLS: userTlsMean,
      MeanIntimacy: userIntimacyMean,
      MeanPassion: userPassionMean,
      MeanCommitment: userCommitmentMean,
      Ip: formData?.userData?.ip | '',
      UserCountry: formData?.userData?.country_name | '',
      UserCity: formData?.userData?.city | '',
      Date: new Date().toISOString().slice(0, 10),
    });
  }, []);

  return (
    <div className="mt-5 mb-2">
      <div className="tlsCards my-5">
        <ResultCard
          img={'/tls.jpg'}
          className="tls"
          result={<CountUp duration={4} decimals={2} end={userTlsMean} />}
          title={'Love level'}
          description={tlsDescription}
        />
        <ResultCard
          img={'/intimacy.jpg'}
          result={<CountUp duration={4} decimals={2} end={userIntimacyMean} />}
          title={'Intimacy'}
          description={intimacyDescription}
        />
        <ResultCard
          img={'/passion.jpg'}
          result={<CountUp duration={4} decimals={2} end={userPassionMean} />}
          title={'Passion'}
          description={passionDescription}
        />
        <ResultCard
          img={'/commitment.jpg'}
          result={<CountUp duration={4} decimals={2} end={userCommitmentMean} />}
          title={'Commitment'}
          description={commtimentDescription}
        />
      </div>
      <div>
        <p>Want to find out more about the Triangular Love Theory and Triangular Love Scale?</p>
      </div>
      <div className="text-left refList">
        {REFS.map((ref, index) => (ref.type === 'book' ? <BookRef key={index} {...ref} /> : <Ref nobold key={index} {...ref} />))}
      </div>
    </div>
  );
}
