import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import React from 'react';

export default function Questions({ formData, errors, langData, handleChange }) {
  const renderQuestion = (index, question, maxText, minText) => (
    <div className="my-5" key={`tls${index + 1}`}>
      <FormControl component="fieldset" fullWidth error={errors.includes(`tls${index + 1}`)}>
        <FormLabel component="legend">{question}</FormLabel>
        <RadioGroup className="m-auto" row value={formData[`tls${index + 1}`]} onChange={handleChange} name={`tls${index + 1}`}>
          <FormControlLabel
            className="qa"
            value="1"
            control={<Radio color="primary" size={'small'} />}
            label={index % 5 === 0 ? minText : ''}
            labelPlacement="start"
          />
          <FormControlLabel className="qa" value="2" control={<Radio color="primary" size={'small'} />} label="" labelPlacement="start" />
          <FormControlLabel className="qa" value="3" control={<Radio color="primary" size={'small'} />} label=" " labelPlacement="start" />
          <FormControlLabel className="qa" value="4" control={<Radio color="primary" size={'small'} />} label=" " labelPlacement="top" />
          <FormControlLabel
            className="qa"
            value="5"
            control={<Radio color="primary" size={'small'} />}
            label={index % 5 === 0 ? maxText : ''}
            labelPlacement="end"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );

  return (
    <div className="my-5 ">{langData.questions.map((question, index) => renderQuestion(index, question, langData.rateMax, langData.rateMin))}</div>
  );
}
