import React from 'react';
import Container from '@material-ui/core/Container';
import { Card, CardActionArea, CardContent, Typography, makeStyles } from '@material-ui/core';
import { FaPencil, FaBook } from 'react-icons/fa6';
import { Helmet } from 'react-helmet';
const useStyles = makeStyles({
  dInline: {
    display: 'inline-block',
    margin: '20px',
    borderRadius: '20px',
    width: '350px',
    height: '170px',
  },
  media: { height: '80px', width: '80px', backgroundSize: 'contain' },
  actionArea: { height: '100%', outline: 'none' },
});

export default function DefaultContent({ setResearch, setTest }) {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />
        <title>Triangular Love Scale (TLS-15)</title>
        <meta
          name="keywords"
          content="Romantic love, love, cross-cultural, affection, relationship, romantic relationship, intimacy, passion, commitment, Sternberg, research, study, science, test your love, what is my love, tls-15, love research"
        ></meta>
        <meta name="author" content="Marta Kowal"></meta>
        <meta
          name="description"
          content="Validated Triangular Love Scale (TLS-15) to measure romantic love experiences based on a large-scale cross-cultural study in 155 countries."
        />
        <meta property="og:type" content="Article" />
        <meta property="og:title" content="Triangular Love Scale (TLS-15)" />
        <meta
          property="og:description"
          content="Validated Triangular Love Scale (TLS-15) to measure romantic love experiences based on a large-scale cross-cultural study in 155 countries."
        />
        <meta property="og:image" content="https://martakowal.com/passion.jpg" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:url" content="https://martakowal.com/tls" />
      </Helmet>
      <Container maxWidth="md" align="center">
        <Typography variant="h4" className="my-5" component="h1">
          The short version of the Triangular Love Scale (TLS-15)
        </Typography>
        <Typography variant="h4" component="h2">
          Do you want to:
        </Typography>
        <div>
          <Card className={classes.dInline} variant="outlined">
            <CardActionArea focusVisibleClassName="" onClick={() => setResearch()} className={classes.actionArea}>
              <FaBook className={classes.media} />
              <CardContent>
                <Typography color="textPrimary">Use the TLS-15 in your research?</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card className={classes.dInline} variant="outlined">
            <CardActionArea onClick={() => setTest()} className={classes.actionArea}>
              <FaPencil className={classes.media} />
              <CardContent>
                <Typography color="textPrimary">Take the love test, discover and compare your love level with others?</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      </Container>
    </>
  );
}
