import React, { useState } from 'react';
import '../../App.css';
import Container from '@material-ui/core/Container';
import { GoogleSpreadsheet } from "google-spreadsheet";
import { Box, Button, Checkbox, FormControl, Icon, Input, InputLabel, ListItemText, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';
import $ from "jquery";



export default function Letter() {
    const [savingData, setSavingData] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [firstName, setFirstName] = React.useState(null);
    const [lastName, setLastName] = React.useState(null);
    const [projectId, setProjectId] = React.useState(null);
    const [department, setDepartment] = React.useState(null);
    const [institution, setInstitution] = React.useState(null);
    const [institutionsCity, setInstitutionsCity] = React.useState(null);
    const [institutionsCountry, setInstitutionsCountry] = React.useState(null);
    const [institutionalEmail, setInstitutionalEmail] = React.useState(null);
    const [intoFrom, setIntoFrom] = React.useState(null);
    const [fromWhichCountry, setFromWhichCountry] = React.useState(null);
    const [response, setResponse] = React.useState(null);
    const [contributions, setContributions] = React.useState([]);

    const SPREADSHEET_ID = "1VZgIrLiGtGony0vhEU_MDQ046MHG-dFuENObum8TyY8";
    const SHEET_ID = "1942078382";
    const CLIENT_EMAIL = "marta-website@martawebsite.iam.gserviceaccount.com";
    const PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC+s079q0os2EZm\n9MRhpBzvmrr83ZFUJh1GZPbuXgYr0LOyzW/6HRmX9FSo7i+cB8CK7GMklxyPK5vI\nrbAcWJhFnN3DarwP54CeKgcVwXHhYkn7ZaU1MjgIBZYZDVtBdaURwGUhtyadS+GY\nJ4PcwUS5swN8IJGUfkoXfljSuWDQ081Qj1m0xjaFjlSQrjDnGM1dnfeFRI7wAd30\nvGmwK2h1hx7QnJjYgO+12fcz7fWACbAv7Z9n+/Jq/M2cBI5YpIQTqrs6n6VWMcrO\nIKM9h+FmBX9S0qKe+r7lXeBRroWWVjVMgp8vrz7NdRSsdTWGTNxQvpFWH4H7VnF6\nDWTYue9/AgMBAAECggEAHHabTSh3tJ7T+e0Bsh3dMHQqAbEOeSlhMuRsqOJsIwu5\nA/CUN0LdqD0QinsBVyrJtdKXmlqwVBGVucvBiVGTP3ICSvck5RjQLTX9AxBxr3V3\nW73C/WAmqyfY90SeQMnO9AnWmRmC+spcfH81YsnkDfWRXmxms5Zv96Fk9OWKH6ZN\neF4eDgsOc1C1ij4nXiknZ3EzCKp9jDJu79x/VqlGZRHDpMVE5F225DlabuZ4TDJ2\nBHkvjuOM3KeOeyeEY+cGE/+szdJzI7CFp57UEkzmFKLsIFJmkWvCqmHgyzYgF2Zw\nM9Mrbf+b+CHP3Wuu0wyLc6l4k4wJ3QCWh2p0HG81VQKBgQD89cs3a8PO6a8a22Sr\nPd8mNAWwKo1KbnwwVxvG0FZsU/BK4/mY51v3uFoVjxxd+caxNjDSB2M1AqJfSdd0\nz/yLngC6ugF5zqdjR7sgLH2zqnCwFp2U6LYDBhvUonSIHmN0OdHNH2RJi5pDw3eb\n8Jh8fC5x3R6k/3vk5BQU2O3rgwKBgQDA/fqkL1dr19xBIznxcTkfnb5EOeEhlalG\nok4Z0lMlGOIXBprekycpC4H3dH4BYJTy+Tetr8u9koemm/X8pKcQVNE3MOqQZ/ln\nZQ89He8hNGQHV/ys60KtXGXNvEd7Zw3bHE/XfV41JtWn9aW7tcNZNUDtf62mj2pk\nhta7May/VQKBgC8puLDlk8VxRmeQfi17RK6/xku5M0ysWtU8eH5R+7sIz2V3KeM7\nR6dhJNKeMyDGYChMPT/7hqCslA+GGfi6ipCRbftikMHgKM9W4Wm3QdyMq6P5I3pP\nBRh9PBUiEO4MFGjoGtjOwIkB0UpPGG7npdaX5KTwIDWNONFNQP0+EOX3AoGAbqfx\ns3w1iu89KdgR3B9IWpluw93P+Rq/8XEUiPewlr+6uusdl2sWdBFWFtWjfhSrstk0\nlJEMqAjBYMVh+NdLtzB6uUOB6wTC2wb2AtwMBaTticxoY5mNJKuq75j5/4aqSizk\nlrEaeC1pKiHOQl/Iv3iqXsLrhWoL3dH2g2TLIPUCgYADiMD8LTf55gfmZ04pp/9H\n9L62WaVaBdlqCAIdZ4aYJMw3uJRteAKDnoD+La/SS6DWlD1GbkwgJdXkOEx8tYLF\nszxqIJaxXI4hax5E/QTdtWvUTSBVPLyscAIgRQC7QX2W5wz116uuijV73l/UULN7\nqPhvZys7FFo0kvpUM5QFwg==\n-----END PRIVATE KEY-----\n";
    const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

    const check = async () => {
        return $.getJSON('https://json.geoiplookup.io/', function (data) {
            return JSON.stringify(data, null, 2);
        })      
    }

    const appendSpreadsheet = async (row) => {
        try {
            await doc.useServiceAccountAuth({
                client_email: CLIENT_EMAIL,
                private_key: PRIVATE_KEY,
            });
            await doc.loadInfo();
            const sheet = doc.sheetsById[SHEET_ID];
            const result = await sheet.addRow(row);
            await setResponse(await result);
        } catch (e) {
            console.error('Error: ', e);
        }
    };
    
    const handleForm = async () => {      
        if (firstName !== null && lastName !== null && projectId !== null && institution !== null && institutionsCity !== null && institutionsCountry !== null && institutionalEmail !== null && contributions.length !== 0) {
            await check().then((data) => {
                setSavingData(true);
                saveData(data);
                setError(false);
            }); 
        } else {
            setError(true);
        }
    }

    const saveData = (data) => {
        const contributionsString = contributions.join(", ");
        const newRow = {
            First_Name: firstName,
            Last_Name: lastName,
            Your_projects_ID: projectId,
            Department: department,
            Institution: institution,
            Institutions_city: institutionsCity,
            Institutions_country: institutionsCountry,
            Institutional_email_address: institutionalEmail,
            Contribution: contributionsString,
            IntoFrom: intoFrom,
            From_which_countryCountries: fromWhichCountry,
            Ip: data.ip, 
            UserCountry: data.country_name, 
            UserCity: data.city, 
            Date: new Date().toISOString().slice(0, 10)
        };
        appendSpreadsheet(newRow);
    }

    const contributionsList = [
        'Leading the translation team',
        'Translation of the survey',
        'Recruitment of participants',
      ];

    const handleContributions = (event) => {
        setContributions(event.target.value);
    };



    return (
        <Container maxWidth="md">
           
            {error ? <Box mt={5}><Typography variant="h4" className="error" gutterBottom>Fill in all the required fields</Typography> </Box>: ""}
            {
                savingData ? response === null ? 
                <Box>
                    <Typography variant="h4" className="header" gutterBottom>Saving your data!</Typography>
                    <ReactLoading type="spinningBubbles" color="#000" height={'20%'} width={'20%'} />
                </Box> : 
                    <Box>
                        <Typography variant="h5" className="header" gutterBottom>Thank you! Your responses have been saved.</Typography>
                        <Box my={10} ><Link to="/home"><Button variant="contained" size="large" color="primary">Go back to the main page</Button></Link></Box>
                    </Box> :
                    <> 
                        <Typography variant="h4" className="header" gutterBottom>Please, fill in the questions below:</Typography>
                        <form autoComplete="off">
                            <FormControl fullWidth >
                                <TextField required id="FirstName" label="First name" onChange={(e) => setFirstName(e.target.value)} />
                                <TextField required id="LastName" label="Last name" onChange={(e) => setLastName(e.target.value)} />
                                <TextField required id="ProjectId" label="Your project's ID" onChange={(e) => setProjectId(e.target.value)} />
                                <TextField id="Department" label="Department" onChange={(e) => setDepartment(e.target.value)} />
                                <TextField required id="Institution" label="Institution" onChange={(e) => setInstitution(e.target.value)} />
                                <TextField required id="InstitutionsCity" label="Institution's city" onChange={(e) => setInstitutionsCity(e.target.value)} />
                                <TextField required id="InstitutionsCountry" label="Institution's country" onChange={(e) => setInstitutionsCountry(e.target.value)} />
                                <TextField required id="InstitutionalEmail" label="Institutional email address" onChange={(e) => setInstitutionalEmail(e.target.value)} />
                                <FormControl fullWidth>
                                    <InputLabel required id="contributions">What was your contribution</InputLabel>
                                    <Select
                                    labelId="contributions"
                                    id="contributionSelect"
                                    multiple
                                    value={contributions}
                                    onChange={handleContributions}
                                    input={<Input />}
                                    renderValue={(selected) => selected.join(', ')}
                                    >
                                    {contributionsList.map((name) => (
                                        <MenuItem key={name} value={name}>
                                        <Checkbox checked={contributions.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                                {contributions.includes(contributionsList[1]) ? <TextField id="TranslationLanguage" label="Into/from which language?" required={contributions.includes(contributionsList[1])} onChange={(e) => setIntoFrom(e.target.value)} /> : "" }
                                {contributions.includes(contributionsList[2]) ? <TextField id="ParticipantsRecrutingCountry" required={contributions.includes(contributionsList[2])} label="From which country/countries were you recruiting the participants?" onChange={(e) => setFromWhichCountry(e.target.value)} /> : "" }
                            </FormControl>
                            <Box mt={5}>
                                <Typography variant="h5" gutterBottom>Click the button below to save your responses!</Typography>
                                <Button onClick={handleForm} variant="contained" color="primary" endIcon={<Icon>send</Icon>}>Send</Button>
                            </Box>
                        </form>
                    </>
                    
            }
             </Container>
    );
}


