import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import ImgMediaCard from '../card/ImgMediaCard';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

export default function Home() {
  return (
    <Container maxWidth="xl" className="home">
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        spacing={3}
        style={{
          paddingTop: '10vh',
        }}
      >
        <Grid item md={6} xs={12} className="findOut">
          <Typography variant="h2" color="primary">
            <b>Find out more about...</b>{' '}
          </Typography>
        </Grid>
        <Grid item md={3} xs={12} align="center">
          <ImgMediaCard
            alt="study"
            imgSrc="/map.png"
            title="International Study"
            mainTitle="International Study"
            mainDescription=""
            linkSrc="/study"
          />
        </Grid>
        <Grid item md={3} xs={12} align="center">
          <ImgMediaCard alt="game" imgSrc="/game.png" title="Attractive Game" mainTitle="E-attractive Game" mainDescription="" linkSrc="/game" />
        </Grid>
        <Grid item md={3} xs={12} align="center">
          <ImgMediaCard alt="papers" imgSrc="/publications.png" title="Publications" mainTitle="Publications" mainDescription="" linkSrc="/papers" />
        </Grid>
        <Grid item md={3} xs={12} align="center">
          <ImgMediaCard alt="about" imgSrc="/about.jpg" title="Bio" mainTitle="About me" mainDescription="" linkSrc="/About" />
        </Grid>
      </Grid>
    </Container>
  );
}
