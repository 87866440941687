import React, { useState } from 'react';
import '../../App.css';
import { MenuItem, Select, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import Link from '@material-ui/core/Link';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import ReactLoading from 'react-loading';
import $ from 'jquery';

export default function Sugar() {
  const [consent, setConsent] = React.useState('false');

  const [id, setId] = React.useState(0);
  const [firstName, setFirstName] = React.useState(null);
  const [middleNameIf, setMiddleNameIf] = React.useState('No');
  const [translationHelp, setTranslationHelp] = React.useState(null);
  const [lang, setLang] = React.useState(null);
  const [translationLead, setTranslationLead] = React.useState(null);
  const [middleName, setMiddleName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);

  const [numberOfAffiliations, setNumberOfAffiliations] = React.useState(1);

  const [firstDepartment, setFirstDepartment] = React.useState(null);
  const [firstInstitution, setFirstInstitution] = React.useState(null);
  const [firstInstitutionCity, setFirstInstitutionCity] = React.useState(null);
  const [firstInstitutionCountry, setFirstInstitutionCountry] =
    React.useState(null);

  const [secondDepartment, setSecondDepartment] = React.useState(null);
  const [secondInstitution, setSecondInstitution] = React.useState(null);
  const [secondInstitutionCity, setSecondInstitutionCity] =
    React.useState(null);
  const [secondInstitutionCountry, setSecondInstitutionCountry] =
    React.useState(null);

  const [thirdDepartment, setThirdDepartment] = React.useState(null);
  const [thirdInstitution, setThirdInstitution] = React.useState(null);
  const [thirdInstitutionCity, setThirdInstitutionCity] = React.useState(null);
  const [thirdInstitutionCountry, setThirdInstitutionCountry] =
    React.useState(null);

  const [email, setEmail] = React.useState(null);
  const [emailConf, setEmailConf] = React.useState(null);

  const [comments, setComments] = React.useState('');

  const [signed, setSigned] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [userData, setData] = React.useState({});
  const [response, setResponse] = React.useState(null);
  const SPREADSHEET_ID = '1VZgIrLiGtGony0vhEU_MDQ046MHG-dFuENObum8TyY8';
  const SHEET_ID = '606206911';
  const CLIENT_EMAIL = 'marta-website@martawebsite.iam.gserviceaccount.com';
  const PRIVATE_KEY =
    '-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC+s079q0os2EZm\n9MRhpBzvmrr83ZFUJh1GZPbuXgYr0LOyzW/6HRmX9FSo7i+cB8CK7GMklxyPK5vI\nrbAcWJhFnN3DarwP54CeKgcVwXHhYkn7ZaU1MjgIBZYZDVtBdaURwGUhtyadS+GY\nJ4PcwUS5swN8IJGUfkoXfljSuWDQ081Qj1m0xjaFjlSQrjDnGM1dnfeFRI7wAd30\nvGmwK2h1hx7QnJjYgO+12fcz7fWACbAv7Z9n+/Jq/M2cBI5YpIQTqrs6n6VWMcrO\nIKM9h+FmBX9S0qKe+r7lXeBRroWWVjVMgp8vrz7NdRSsdTWGTNxQvpFWH4H7VnF6\nDWTYue9/AgMBAAECggEAHHabTSh3tJ7T+e0Bsh3dMHQqAbEOeSlhMuRsqOJsIwu5\nA/CUN0LdqD0QinsBVyrJtdKXmlqwVBGVucvBiVGTP3ICSvck5RjQLTX9AxBxr3V3\nW73C/WAmqyfY90SeQMnO9AnWmRmC+spcfH81YsnkDfWRXmxms5Zv96Fk9OWKH6ZN\neF4eDgsOc1C1ij4nXiknZ3EzCKp9jDJu79x/VqlGZRHDpMVE5F225DlabuZ4TDJ2\nBHkvjuOM3KeOeyeEY+cGE/+szdJzI7CFp57UEkzmFKLsIFJmkWvCqmHgyzYgF2Zw\nM9Mrbf+b+CHP3Wuu0wyLc6l4k4wJ3QCWh2p0HG81VQKBgQD89cs3a8PO6a8a22Sr\nPd8mNAWwKo1KbnwwVxvG0FZsU/BK4/mY51v3uFoVjxxd+caxNjDSB2M1AqJfSdd0\nz/yLngC6ugF5zqdjR7sgLH2zqnCwFp2U6LYDBhvUonSIHmN0OdHNH2RJi5pDw3eb\n8Jh8fC5x3R6k/3vk5BQU2O3rgwKBgQDA/fqkL1dr19xBIznxcTkfnb5EOeEhlalG\nok4Z0lMlGOIXBprekycpC4H3dH4BYJTy+Tetr8u9koemm/X8pKcQVNE3MOqQZ/ln\nZQ89He8hNGQHV/ys60KtXGXNvEd7Zw3bHE/XfV41JtWn9aW7tcNZNUDtf62mj2pk\nhta7May/VQKBgC8puLDlk8VxRmeQfi17RK6/xku5M0ysWtU8eH5R+7sIz2V3KeM7\nR6dhJNKeMyDGYChMPT/7hqCslA+GGfi6ipCRbftikMHgKM9W4Wm3QdyMq6P5I3pP\nBRh9PBUiEO4MFGjoGtjOwIkB0UpPGG7npdaX5KTwIDWNONFNQP0+EOX3AoGAbqfx\ns3w1iu89KdgR3B9IWpluw93P+Rq/8XEUiPewlr+6uusdl2sWdBFWFtWjfhSrstk0\nlJEMqAjBYMVh+NdLtzB6uUOB6wTC2wb2AtwMBaTticxoY5mNJKuq75j5/4aqSizk\nlrEaeC1pKiHOQl/Iv3iqXsLrhWoL3dH2g2TLIPUCgYADiMD8LTf55gfmZ04pp/9H\n9L62WaVaBdlqCAIdZ4aYJMw3uJRteAKDnoD+La/SS6DWlD1GbkwgJdXkOEx8tYLF\nszxqIJaxXI4hax5E/QTdtWvUTSBVPLyscAIgRQC7QX2W5wz116uuijV73l/UULN7\nqPhvZys7FFo0kvpUM5QFwg==\n-----END PRIVATE KEY-----\n';
  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

  const check = async () => {
    return $.getJSON('https://json.geoiplookup.io/', function (data) {
      return JSON.stringify(data, null, 2);
    });
  };

  const appendSpreadsheet = async (row) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      await doc.loadInfo();
      const sheet = doc.sheetsById[SHEET_ID];
      const result = await sheet.addRow(row);
      await setResponse(await result);
    } catch (e) {
      console.error('Error: ', e);
    }
  };

  const capitalizeFirstLetter = (phrase) => {
    return phrase !== null
      ? phrase
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      : null;
  };

  const handleConsent = async (event) => {
    setData(await check());
    setConsent(event.target.value);
  };

  const handleForm = () => {
    if (
      consent === 'true' &&
      firstName !== null &&
      lastName !== null &&
      firstDepartment !== null &&
      firstInstitution !== null &&
      firstInstitutionCity !== null &&
      firstInstitutionCountry !== null &&
      translationHelp !== null &&
      translationLead !== null &&
      email !== null
    ) {
      saveData(userData);
      setError(false);
      setSigned(true);
    } else {
      setError(true);
    }
  };

  const saveData = (userData) => {
    const newRow = {
      Id: id,
      Consent: consent,
      FirstName: capitalizeFirstLetter(firstName),
      MiddleName: middleName !== null ? capitalizeFirstLetter(middleName) : '',
      LastName: capitalizeFirstLetter(lastName),
      FirstDepartment: capitalizeFirstLetter(firstDepartment),
      FirstInstitution: capitalizeFirstLetter(firstInstitution),
      FirstInstitutionCity: capitalizeFirstLetter(firstInstitutionCity),
      FirstInstitutionCountry: capitalizeFirstLetter(firstInstitutionCountry),

      SecondDepartment:
        secondDepartment !== null
          ? capitalizeFirstLetter(secondDepartment)
          : '',
      SecondInstitution:
        secondInstitution !== null
          ? capitalizeFirstLetter(secondInstitution)
          : '',
      SecondInstitutionCity:
        secondInstitutionCity !== null
          ? capitalizeFirstLetter(secondInstitutionCity)
          : '',
      SecondInstitutionCountry:
        secondInstitutionCountry !== null
          ? capitalizeFirstLetter(secondInstitutionCountry)
          : '',

      ThirdDepartment:
        thirdDepartment !== null ? capitalizeFirstLetter(thirdDepartment) : '',
      ThirdInstitution:
        thirdInstitution !== null
          ? capitalizeFirstLetter(thirdInstitution)
          : '',
      ThirdInstitutionCity:
        thirdInstitutionCity !== null
          ? capitalizeFirstLetter(thirdInstitutionCity)
          : '',
      ThirdInstitutionCountry:
        thirdInstitutionCountry !== null
          ? capitalizeFirstLetter(thirdInstitutionCountry)
          : '',

      Email: capitalizeFirstLetter(email),
      HelpInTranslation: translationHelp,
      Languages: lang,
      TranslationLead: translationLead,
      Comments: comments,
      Ip: userData.ip,
      UserCountry: userData.country_name,
      UserCity: userData.city,
      Date: new Date().toISOString().slice(0, 10),
    };
    appendSpreadsheet(newRow);
  };

  return (
    <Container maxWidth="md" className="">
      {!signed ? (
        <Box>
          <Typography variant="h4" className="header" gutterBottom>
            Do you accept to become a co-author of the paper entitled: <br />{' '}
            <Typography variant="h6">
              <i>
                Sex for Resources in Human Mating: Acceptance of Sugar
                Relationships in a Cross-Cultural Sample
              </i>
              ?
            </Typography>
          </Typography>
          <FormControl component="fieldset">
            <Typography variant="h6" gutterBottom></Typography>
            <FormLabel component="legend">
              The second draft of the manuscript can be accessed{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.google.com/document/d/1gglRu1Q9HAr46PopHeMD3XR6aeX_VK7n9Vxnxd4wx3U/edit?usp=sharing"
              >
                here
              </a>
            </FormLabel>
            <FormLabel component="legend">
              Tables, figures, and supplementary material can be accessed{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://drive.google.com/drive/folders/1ma_XXxSTDaXBzp1YxisO9dl1aYc6m-4R?usp=sharing"
              >
                here
              </a>
            </FormLabel>
            <RadioGroup
              aria-label="Consent"
              name="Consent"
              value={consent}
              onChange={handleConsent}
            >
              <FormControlLabel
                value={'true'}
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                value={'false'}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </FormControl>

          {consent === 'true' ? (
            <Box pt={5}>
              <form noValidate autoComplete="off">
                <Typography variant="h4" gutterBottom>
                  Please, fill in the questions below if you accept to become a
                  co-author of the paper entitled: <br />{' '}
                  <Typography variant="h6" gutterBottom>
                    <i>
                      Sex for Resources in Human Mating: Acceptance of Sugar
                      Relationships in a Cross-Cultural Sample?
                    </i>
                  </Typography>
                </Typography>

                <Typography variant="h6" gutterBottom>
                  What is your project’s number/ID/res?*
                </Typography>
                <TextField
                  type="number"
                  required
                  error={error & (id === 0) ? true : false}
                  id="standard-full-width"
                  fullWidth
                  label="e.g. 665"
                  onChange={(e) => setId(e.target.value)}
                />
                <Box py={3}>
                  <FormLabel component="legend">
                    (If you do not remember your project’s ID, please, go back
                    to one of the first emails from me. Possibly with the title
                    “Data collection [large-scale study]”. There must have been
                    included the link for data collection and your personal ID
                    number at the end of the email).
                  </FormLabel>
                </Box>
                <Typography variant="h6" gutterBottom>
                  First name
                </Typography>
                <TextField
                  required
                  error={error & (firstName === null) ? true : false}
                  id="standard-full-width"
                  fullWidth
                  label="e.g. Marta"
                  onChange={(e) => setFirstName(e.target.value)}
                />

                <Typography variant="h6" gutterBottom>
                  Do you wish to add your middle name to the co-author list of
                  the manuscript?{' '}
                </Typography>
                <RadioGroup
                  aria-label="Middle Name"
                  name="middleName"
                  value={middleNameIf}
                  onChange={(e) => setMiddleNameIf(e.target.value)}
                >
                  <FormControlLabel
                    value={'true'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'false'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>

                {middleNameIf === 'true' ? (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Middle name
                    </Typography>
                    <TextField
                      required
                      error={error & (middleName === null) ? true : false}
                      id="standard-full-width"
                      fullWidth
                      label="e.g. Agnieszka"
                      onChange={(e) => setMiddleName(e.target.value)}
                    />
                  </>
                ) : null}

                <Typography variant="h6" gutterBottom>
                  Last name
                </Typography>
                <TextField
                  required
                  error={error & (lastName === null) ? true : false}
                  id="standard-full-width"
                  fullWidth
                  label="e.g. Kowal"
                  onChange={(e) => setLastName(e.target.value)}
                />

                <Typography variant="h6" gutterBottom>
                  How many affiliations do you have?
                </Typography>
                <Select
                  labelId="affiliations"
                  id="affiliations"
                  value={numberOfAffiliations}
                  label="Number of affiliations"
                  onChange={(e) => setNumberOfAffiliations(e.target.value)}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                </Select>
                <Box py={3}>
                  <Typography variant="h6" gutterBottom>
                    Your affiliation:
                  </Typography>
                  <Box pl={3}>
                    <Typography variant="h6" gutterBottom>
                      Department
                    </Typography>
                    <TextField
                      required
                      error={error & (firstDepartment === null) ? true : false}
                      id="standard-full-width"
                      fullWidth
                      label="e.g. Department of Psychology"
                      onChange={(e) => setFirstDepartment(e.target.value)}
                    />
                    <Typography variant="h6" gutterBottom>
                      Institution
                    </Typography>
                    <TextField
                      required
                      error={error & (firstInstitution === null) ? true : false}
                      id="standard-full-width"
                      fullWidth
                      label="e.g. University of Wrocław"
                      onChange={(e) => setFirstInstitution(e.target.value)}
                    />
                    <Typography variant="h6" gutterBottom>
                      Institution city
                    </Typography>
                    <TextField
                      required
                      error={
                        error & (firstInstitutionCity === null) ? true : false
                      }
                      id="standard-full-width"
                      fullWidth
                      label="e.g. Wrocław"
                      onChange={(e) => setFirstInstitutionCity(e.target.value)}
                    />
                    <Typography variant="h6" gutterBottom>
                      Institution country
                    </Typography>
                    <TextField
                      required
                      error={
                        error & (firstInstitutionCountry === null)
                          ? true
                          : false
                      }
                      id="standard-full-width"
                      fullWidth
                      label="e.g. Poland"
                      onChange={(e) =>
                        setFirstInstitutionCountry(e.target.value)
                      }
                    />
                  </Box>
                </Box>
                {numberOfAffiliations !== 1 ? (
                  <Box py={3}>
                    <Typography variant="h6" gutterBottom>
                      Second affiliation:
                    </Typography>
                    <Box pl={3}>
                      <Typography variant="h6" gutterBottom>
                        Department
                      </Typography>
                      <TextField
                        required
                        error={
                          error & (secondDepartment === null) ? true : false
                        }
                        id="standard-full-width"
                        fullWidth
                        label="e.g. Department of Psychology"
                        onChange={(e) => setSecondDepartment(e.target.value)}
                      />
                      <Typography variant="h6" gutterBottom>
                        Institution
                      </Typography>
                      <TextField
                        required
                        error={
                          error & (secondInstitution === null) ? true : false
                        }
                        id="standard-full-width"
                        fullWidth
                        label="e.g. University of Wrocław"
                        onChange={(e) => setSecondInstitution(e.target.value)}
                      />
                      <Typography variant="h6" gutterBottom>
                        Institution city
                      </Typography>
                      <TextField
                        required
                        error={
                          error & (secondInstitutionCity === null)
                            ? true
                            : false
                        }
                        id="standard-full-width"
                        fullWidth
                        label="e.g. Wrocław"
                        onChange={(e) =>
                          setSecondInstitutionCity(e.target.value)
                        }
                      />
                      <Typography variant="h6" gutterBottom>
                        Institution country
                      </Typography>
                      <TextField
                        required
                        error={
                          error & (secondInstitutionCountry === null)
                            ? true
                            : false
                        }
                        id="standard-full-width"
                        fullWidth
                        label="e.g. Poland"
                        onChange={(e) =>
                          setSecondInstitutionCountry(e.target.value)
                        }
                      />
                    </Box>
                  </Box>
                ) : null}

                {numberOfAffiliations === 3 ? (
                  <Box py={3}>
                    <Typography py={3} variant="h6" gutterBottom>
                      Third affiliation:
                    </Typography>
                    <Box pl={3}>
                      <Typography variant="h6" gutterBottom>
                        Third department
                      </Typography>
                      <TextField
                        required
                        error={
                          error & (thirdDepartment === null) ? true : false
                        }
                        id="standard-full-width"
                        fullWidth
                        label="e.g. Department of Psychology"
                        onChange={(e) => setThirdDepartment(e.target.value)}
                      />
                      <Typography variant="h6" gutterBottom>
                        Institution
                      </Typography>
                      <TextField
                        required
                        error={
                          error & (thirdInstitution === null) ? true : false
                        }
                        id="standard-full-width"
                        fullWidth
                        label="e.g. University of Wrocław"
                        onChange={(e) => setThirdInstitution(e.target.value)}
                      />
                      <Typography variant="h6" gutterBottom>
                        Institution city
                      </Typography>
                      <TextField
                        required
                        error={
                          error & (thirdInstitutionCity === null) ? true : false
                        }
                        id="standard-full-width"
                        fullWidth
                        label="e.g. Wrocław"
                        onChange={(e) =>
                          setThirdInstitutionCity(e.target.value)
                        }
                      />
                      <Typography variant="h6" gutterBottom>
                        Institution country
                      </Typography>
                      <TextField
                        required
                        error={
                          error & (thirdInstitutionCountry === null)
                            ? true
                            : false
                        }
                        id="standard-full-width"
                        fullWidth
                        label="e.g. Poland"
                        onChange={(e) =>
                          setThirdInstitutionCountry(e.target.value)
                        }
                      />
                    </Box>
                  </Box>
                ) : null}

                <Typography variant="h6" gutterBottom>
                  Email address
                </Typography>
                <TextField
                  required
                  error={error & (email === null) ? true : false}
                  id="standard-full-width"
                  fullWidth
                  label="e.g. marta7kowal@gmail.com (please, choose only one email address)"
                  onChange={(e) => setEmail(e.target.value)}
                />

                <Typography variant="h6" gutterBottom>
                  Please, type your email address again
                </Typography>
                <TextField
                  required
                  error={emailConf !== email ? true : false}
                  id="standard-full-width"
                  fullWidth
                  label="e.g. marta7kowal@gmail.com (please, choose only one email address) "
                  onChange={(e) => setEmailConf(e.target.value)}
                />
                <Typography variant="h6" gutterBottom>
                  Did you help with the translation of the survey?{' '}
                </Typography>
                <RadioGroup
                  aria-label=""
                  name="translationHelp"
                  value={translationHelp}
                  onChange={(e) => setTranslationHelp(e.target.value)}
                >
                  <FormControlLabel
                    value={'true'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'false'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>

                {translationHelp === 'true' && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Into which language(s)?
                    </Typography>
                    <TextField
                      required
                      error={error & (lang === null) ? true : false}
                      id="standard-full-width"
                      fullWidth
                      label="Polish"
                      onChange={(e) => setLang(e.target.value)}
                    />
                  </>
                )}
                <Typography variant="h6" gutterBottom>
                  Did you lead the translation process for your local
                  language(s)?{' '}
                </Typography>
                <RadioGroup
                  aria-label=""
                  name="translationLead"
                  value={translationLead}
                  onChange={(e) => setTranslationLead(e.target.value)}
                >
                  <FormControlLabel
                    value={'true'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'false'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                <Typography variant="h6" gutterBottom>
                  Is there anything you would like to share, ask or comment?
                </Typography>
                <TextField
                  id="standard-full-width"
                  fullWidth
                  label="Comments"
                  onChange={(e) => setComments(e.target.value)}
                />

                {error ? (
                  <Box my={5} align="center">
                    <Typography variant="h3" color="error">
                      Please, fill missing information.
                    </Typography>
                  </Box>
                ) : (
                  ''
                )}
                <Box my={10} align="center" bgcolor="background.paper">
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    endIcon={<CheckIcon />}
                    onClick={handleForm}
                  >
                    Send form
                  </Button>
                </Box>
              </form>
            </Box>
          ) : (
            ''
          )}
        </Box>
      ) : (
        <Box my={5} align="center" bgcolor="background.paper">
          {response === null ? (
            <Box>
              <Typography variant="h4" className="header" gutterBottom>
                Saving your data!
              </Typography>
              <ReactLoading
                type="spinningBubbles"
                color="#000"
                height={'20%'}
                width={'20%'}
              />
            </Box>
          ) : (
            <Box>
              <Typography variant="h4" className="header" gutterBottom>
                Thank you for filling in the form! Within a week, I will review
                your submission and email you to confirm that you have
                successfully accepted the invitation to become a co-author.
              </Typography>
              <Box my={10}>
                <Link href="/home">
                  <Button variant="contained" size="large" color="primary">
                    Go back to the main page
                  </Button>
                </Link>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Container>
  );
}
