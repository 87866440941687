import React, { useState } from 'react';
import '../../App.css';
import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import Link from '@material-ui/core/Link';
import { GoogleSpreadsheet } from "google-spreadsheet";
import ReactLoading from 'react-loading';
import $ from "jquery";


export default function Collect() {
    const [firstName, setFirstName] = React.useState(null);
    const [lastName, setLastName] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [coordinator, setCoordinator] = React.useState(false);
    const [translationLang, setTranslationLang] = React.useState('');
    const [frontTranslator, setFrontTranslator] = React.useState(null);
    const [backTranslator, setBackTranslator] = React.useState(null);
    const [linguistic, setLinguistic] = React.useState(null);
    const [numberOfCountries, setNumberOfCountries] = React.useState(1);
    const [collectCountries, setCollectCountries] = React.useState({});
    const [withBuddies, setWithBuddies] = React.useState();
    const [numberOfBuddies, setNumberOfBuddies] = React.useState(0);
    const [collectDataBuddy, setCollectDataBuddy] = React.useState({});
    const [comments, setComments] = React.useState(null);
    const [countryInputs, setCountryInputs] = React.useState([]);
    const [collectDataBuddyInputs, setCollectDataBuddyInputs] = React.useState([]);

    const [signed, setSigned] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [userData, setData] = React.useState({});
    const [response, setResponse] = React.useState(null);

    const SPREADSHEET_ID = "1VZgIrLiGtGony0vhEU_MDQ046MHG-dFuENObum8TyY8";
    const SHEET_ID = "1609116790";
    const CLIENT_EMAIL = "marta-website@martawebsite.iam.gserviceaccount.com";
    const PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC+s079q0os2EZm\n9MRhpBzvmrr83ZFUJh1GZPbuXgYr0LOyzW/6HRmX9FSo7i+cB8CK7GMklxyPK5vI\nrbAcWJhFnN3DarwP54CeKgcVwXHhYkn7ZaU1MjgIBZYZDVtBdaURwGUhtyadS+GY\nJ4PcwUS5swN8IJGUfkoXfljSuWDQ081Qj1m0xjaFjlSQrjDnGM1dnfeFRI7wAd30\nvGmwK2h1hx7QnJjYgO+12fcz7fWACbAv7Z9n+/Jq/M2cBI5YpIQTqrs6n6VWMcrO\nIKM9h+FmBX9S0qKe+r7lXeBRroWWVjVMgp8vrz7NdRSsdTWGTNxQvpFWH4H7VnF6\nDWTYue9/AgMBAAECggEAHHabTSh3tJ7T+e0Bsh3dMHQqAbEOeSlhMuRsqOJsIwu5\nA/CUN0LdqD0QinsBVyrJtdKXmlqwVBGVucvBiVGTP3ICSvck5RjQLTX9AxBxr3V3\nW73C/WAmqyfY90SeQMnO9AnWmRmC+spcfH81YsnkDfWRXmxms5Zv96Fk9OWKH6ZN\neF4eDgsOc1C1ij4nXiknZ3EzCKp9jDJu79x/VqlGZRHDpMVE5F225DlabuZ4TDJ2\nBHkvjuOM3KeOeyeEY+cGE/+szdJzI7CFp57UEkzmFKLsIFJmkWvCqmHgyzYgF2Zw\nM9Mrbf+b+CHP3Wuu0wyLc6l4k4wJ3QCWh2p0HG81VQKBgQD89cs3a8PO6a8a22Sr\nPd8mNAWwKo1KbnwwVxvG0FZsU/BK4/mY51v3uFoVjxxd+caxNjDSB2M1AqJfSdd0\nz/yLngC6ugF5zqdjR7sgLH2zqnCwFp2U6LYDBhvUonSIHmN0OdHNH2RJi5pDw3eb\n8Jh8fC5x3R6k/3vk5BQU2O3rgwKBgQDA/fqkL1dr19xBIznxcTkfnb5EOeEhlalG\nok4Z0lMlGOIXBprekycpC4H3dH4BYJTy+Tetr8u9koemm/X8pKcQVNE3MOqQZ/ln\nZQ89He8hNGQHV/ys60KtXGXNvEd7Zw3bHE/XfV41JtWn9aW7tcNZNUDtf62mj2pk\nhta7May/VQKBgC8puLDlk8VxRmeQfi17RK6/xku5M0ysWtU8eH5R+7sIz2V3KeM7\nR6dhJNKeMyDGYChMPT/7hqCslA+GGfi6ipCRbftikMHgKM9W4Wm3QdyMq6P5I3pP\nBRh9PBUiEO4MFGjoGtjOwIkB0UpPGG7npdaX5KTwIDWNONFNQP0+EOX3AoGAbqfx\ns3w1iu89KdgR3B9IWpluw93P+Rq/8XEUiPewlr+6uusdl2sWdBFWFtWjfhSrstk0\nlJEMqAjBYMVh+NdLtzB6uUOB6wTC2wb2AtwMBaTticxoY5mNJKuq75j5/4aqSizk\nlrEaeC1pKiHOQl/Iv3iqXsLrhWoL3dH2g2TLIPUCgYADiMD8LTf55gfmZ04pp/9H\n9L62WaVaBdlqCAIdZ4aYJMw3uJRteAKDnoD+La/SS6DWlD1GbkwgJdXkOEx8tYLF\nszxqIJaxXI4hax5E/QTdtWvUTSBVPLyscAIgRQC7QX2W5wz116uuijV73l/UULN7\nqPhvZys7FFo0kvpUM5QFwg==\n-----END PRIVATE KEY-----\n";
    const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

    const check = async () => {
        return $.getJSON('https://json.geoiplookup.io/', function (data) {
            return JSON.stringify(data, null, 2);
        })
    }

    const appendSpreadsheet = async (row) => {
        try {
            await doc.useServiceAccountAuth({
                client_email: CLIENT_EMAIL,
                private_key: PRIVATE_KEY,
            });
            await doc.loadInfo();
            const sheet = doc.sheetsById[SHEET_ID];
            const result = await sheet.addRow(row);
            await setResponse(await result);
        } catch (e) {
            console.error('Error: ', e);
        }
    };

    const capitalizeFirstLetter = (phrase) => {
            return phrase !== null ? phrase
              .toLowerCase()
              .split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ') : null;
          };
      

    const handleConsent = async (event) => {
        setData(await check());
        setConsent(event.target.value);
    };

    const handleForm = () => {

        if (firstName !== null && lastName !== null && email !== null && coordinator !== null && translationLang !== null && frontTranslator !== null && backTranslator !== null && linguistic !== null && numberOfCountries !== null) {
            saveData();
            setError(false);
            setSigned(true);
        } else {
            setError(true);
        }
    }

    const BuddyDataToString = (buddies) => {
        let buddiesString = "";
        for(let buddy in buddies){
            buddiesString += buddy +
            " Name: " + buddies[buddy]['firstName'] +
            " Last name: " +  buddies[buddy]['lastName'] +
            " Email: " + buddies[buddy]['email'] +  ";\n";
        }

        return buddiesString;
    }

    const countriesToString = (countries) => {
        let countriesString = ''
        
        for(const [key, value] of Object.entries(countries)){
            countriesString += key + " " + value + "; ";
        }

        return countriesString;
    }

    const saveData = () => {
        const newRow = {
            First_Name: firstName,
            Last_Name: lastName,
            Email: email,
            Coordinator: coordinator,
            Translation_Lang: translationLang,
            Front_Translator: frontTranslator,
            Back_Translator: backTranslator,
            Linguistic: linguistic,
            Number_Of_Countries: numberOfCountries,
            Collect_Countries: countriesToString(collectCountries),
            Data_Collected_With_Buddies: withBuddies,
            Number_Of_Buddies: numberOfBuddies,
            Budies_Data: BuddyDataToString(collectDataBuddy),
            Comments: comments
        };
        console.log(newRow);
        appendSpreadsheet(newRow);
    }


    const prepareCountryInputs = (e) => {
        let inputs = [];
        for(let i = 1; i <= e.target.value; i++) {
            inputs.push(<Box pl={3}><Typography variant="h6" gutterBottom>{i}. Country name </Typography><TextField required id="standard-full-width" value={collectCountries === undefined ? "" : collectCountries[i]} fullWidth label="Country" onChange={(e) => handleCountryInput(i, e.target.value)} /></Box>)
        }
        setCountryInputs(inputs);
    }

    const handleCountryInput = (i,val) => {
        let countries = collectCountries;
        countries[i] = val;
        setCollectCountries(countries);
    }

    const prepareBudiesInputs = (e) => {
        let inputs = [];
        for(let i = 1; i <= e.target.value; i++) {
            inputs.push(
            <Box py={3} pl={2}>
                <Typography variant="h6" gutterBottom>Collaborant {i}. Please, write the first name of the Collaborator you want to collect data with </Typography>
                <TextField required id="standard-full-width" name="firstName" fullWidth label="First Name" onChange={(e) => handleBudyInput(i, e)} />
                <Typography variant="h6" gutterBottom>Collaborant {i}. Please, write the last name of the Collaborator you want to collect data with</Typography>
                <TextField required id="standard-full-width" name="lastName" fullWidth label="Last Name" onChange={(e) => handleBudyInput(i, e)} />
                <Typography variant="h6" gutterBottom>Collaborant {i}. Please, write the email address of the Collaborator you want to collect data with </Typography>
                <TextField required id="standard-full-width" name="email" fullWidth label="Email" onChange={(e) => handleBudyInput(i, e)} />
            </Box>)
        }
        setCollectDataBuddyInputs(inputs);
    }

    const handleBudyInput = (i, e) => {
        let buddies = collectDataBuddy;
        buddies[i] === undefined ? buddies[i] = {} : "";
        if(e.target.name === "firstName"){
            buddies[i].firstName = e.target.value; 
        } else if(e.target.name === "lastName"){
            buddies[i].lastName = e.target.value; 
        } else if(e.target.name === "email"){
            buddies[i].email = e.target.value; 
        }
        setCollectDataBuddy(buddies);
    }

    return (
        <Container maxWidth="md">
            {!signed ?
                <Box>
                    <Box pt={5}>
                         <Box><Typography align='center' variant="h6" gutterBottom>(moving on to the...)<Typography align='center' display='inline' variant="h3" gutterBottom><b> Stage 3</b></Typography></Typography></Box>
                            <form noValidate autoComplete="off">
                                <Box py={2}>
                                    <Typography variant="h6" gutterBottom className={error ? "error" : ""}>1.	What is your name? </Typography>
                                    <TextField required error={firstName === null ? true : false} id="standard-full-width" fullWidth label="e.g., Marta" onChange={(e) => setFirstName(e.target.value)} />
                                </Box>
                                <Box py={2}>
                                    <Typography variant="h6" gutterBottom className={error ? "error" : ""}>2.	What is your surname?  </Typography>
                                    <TextField required error={firstName === null ? true : false} id="standard-full-width" fullWidth label="e.g., Kowal" onChange={(e) => setLastName(e.target.value)} />
                                </Box>     
                                <Box py={2}>
                                    <Typography variant="h6" gutterBottom className={error ? "error" : ""}>3.	What is your email address? </Typography>
                                    <TextField required error={email === null ? true : false} id="standard-full-width" fullWidth label="e.g., marta7kowal@gmail.com" onChange={(e) => setEmail(e.target.value)} />
                                </Box>
                                <Box py={2}>
                                    <Typography variant="h6" gutterBottom className={error ? "error" : ""}>4.	Were you a Coordinator of the translation into your local language?</Typography>
                                    <RadioGroup required aria-label="coordinator" name="coordinator" value={coordinator} onChange={(e) => setCoordinator(e.target.value)}>
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                    {
                                        coordinator === "Yes" ?
                                        <Box>
                                            <Typography variant="h6" gutterBottom>4.1. Which language? </Typography>
                                            <TextField value={translationLang} required error={translationLang === '' ? true : false} id="standard-full-width" fullWidth label="" onChange={(e) => setTranslationLang(e.target.value)} />
                                        </Box> : ''
                                    }
                                </Box>
                                <Box py={2}>
                                    <Typography variant="h6" gutterBottom className={error ? "error" : ""}>5.	Were you front-translating the survey?</Typography>
                                    <RadioGroup aria-label="frontTranslator" name="frontTranslator" value={frontTranslator} onChange={(e) => setFrontTranslator(e.target.value)}>
                                        <FormControlLabel  value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                    {
                                        (coordinator === "No" && frontTranslator === "Yes")  ?
                                        <Box>
                                            <Typography variant="h6" gutterBottom>5.1. Which language? </Typography>
                                            <TextField value={translationLang} required error={translationLang === ''  ? true : false} id="standard-full-width" fullWidth label="" onChange={(e) => setTranslationLang(e.target.value)} />
                                        </Box> : ''
                                    }
                                </Box>
                                <Box py={2}>
                                    <Typography variant="h6" gutterBottom className={error ? "error" : ""}>6.	Were you back-translating the survey?</Typography>
                                    <RadioGroup aria-label="backTranslator" name="backTranslator" value={backTranslator} onChange={(e) => setBackTranslator(e.target.value)}>
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                    {
                                        (coordinator === "No" && frontTranslator === "No" && backTranslator === "Yes")  ?
                                        <Box>
                                            <Typography variant="h6" gutterBottom>6.1. Which language? </Typography>
                                            <TextField value={translationLang} required error={translationLang === ''  ? true : false} id="standard-full-width" fullWidth label="" onChange={(e) => setTranslationLang(e.target.value)} />
                                        </Box> : ''
                                    }
                                </Box>
                                <Box py={2}>
                                    <Typography variant="h6" gutterBottom className={error ? "error" : ""}>7.	Were you involved in final linguistic adjustments/corrections?</Typography>
                                    <RadioGroup aria-label="linguistic" name="linguistic" value={linguistic} onChange={(e) => setLinguistic(e.target.value)}>
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>

                                    {
                                        (coordinator === "No" && frontTranslator === "No" && backTranslator === "No" && linguistic === 'Yes')  ?
                                        <Box>
                                            <Typography variant="h6" gutterBottom>7.1. Which language? </Typography>
                                            <TextField value={translationLang} required error={translationLang === null ? true : false} id="standard-full-width" fullWidth label="" onChange={(e) => setTranslationLang(e.target.value)} />
                                        </Box> : ''
                                    }
                                </Box>
                                <Box py={2}>
                                    <Typography variant="h6" gutterBottom className={error ? "error" : ""}>8.	In how many countries will you collect data?</Typography>
                                    <FormControl required>
                                <Select native value={numberOfCountries} onChange={(e) => {setNumberOfCountries(e.target.value), prepareCountryInputs(e)}} name="numberOfCountries">
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                    <option value={6}>6</option>
                                    <option value={7}>7</option>
                                    <option value={8}>8</option>
                                    <option value={9}>9</option>
                                    <option value={10}>10</option>
                                </Select>
                                </FormControl>
                                </Box>
                                <Box py={2}>
                                    <Typography variant="h6" gutterBottom className={error ? "error" : ""}>9.	In which countries will you collect data?</Typography>
                                    {countryInputs.length === 0 ? <Box pl={3}><Typography variant="h6" gutterBottom className={error ? "error" : ""}>1. Country name </Typography><TextField required id="standard-full-width" fullWidth label="Country" onChange={(e) => handleCountryInput(1,e.target.value)} /></Box> :  countryInputs}
                                </Box>
                                <Box py={2}>
                                    <Typography variant="h6" gutterBottom className={error ? "error" : ""}>10. Do you want to collect data with someone else?</Typography>
                                    <RadioGroup aria-label="withBuddies" name="withBuddies" value={withBuddies} onChange={(e) => setWithBuddies(e.target.value)}>
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                    {withBuddies === "Yes" ? 
                                    <Box pl={3}>
                                        <Typography variant="h6" gutterBottom>10.1.	With how many Colleagues of yours do you want to collect data?</Typography>
                                        <Select native value={numberOfBuddies} onChange={(e) => {setNumberOfBuddies(e.target.value), prepareBudiesInputs(e)}} name="numberOfBuddies">
                                            <option value={0}>0</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                            <option value={6}>6</option>
                                            <option value={7}>7</option>
                                            <option value={8}>8</option>
                                            <option value={9}>9</option>
                                            <option value={10}>10</option>
                                        </Select>
                                        {numberOfBuddies !== 0 ? collectDataBuddyInputs : ""}
                                    </Box> : ""}

                                </Box>   
                                <Box py={2}>
                                    <Typography variant="h6" gutterBottom>11.	Do you have any other comments regarding the data collection or survey distribution?</Typography>
                                    <TextField id="standard-full-width" fullWidth label="Comments" onChange={(e) => setComments(e.target.value)} />
                                </Box>

                                {error ? <Box my={5} align="center"><Typography variant="h3" color="error">Please, fill missing information.</Typography></Box> : ""}
                                <Box my={10} align="center" bgcolor="background.paper"><Button variant="contained" size="large" color="primary" endIcon={<CheckIcon />} onClick={handleForm}>Send form</Button></Box>
                            </form>
                        </Box>
                </Box> :
                <Box my={5} align="center" bgcolor="background.paper">    
                    {response === null ? 
                    <Box>
                        <Typography variant="h4" className="header" gutterBottom>Saving your data!</Typography>
                        <ReactLoading type="spinningBubbles" color="#000" height={'20%'} width={'20%'} />
                    </Box> : 
                        <Box>
                            <Typography variant="h4" className="header" gutterBottom>Thank you!</Typography>
                            <Typography variant="h5" className="header" gutterBottom>Please, stay tuned for further instructions.</Typography>
                            <Box my={10} ><Link href="/home"><Button variant="contained" size="large" color="primary">Go back to the main page</Button></Link></Box>
                        </Box>
                    }
                </Box>
            }
        </Container>
    );
}


