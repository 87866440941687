import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import { Button, Typography } from '@material-ui/core';
import Ref from '../../../reference/Ref';
import Flag from 'react-world-flags';
import { LANGUAGES, REFS } from '../StaticData';
import BookRef from '../../../reference/BookRef';

export default function ResearchTLS() {
  return (
    <Container maxWidth="md">
      <Typography variant="h5" className="mt-3" component="h1">
        Here’s the list of all translated and validated versions of the TLS-15.
      </Typography>
      <br />
      <Typography variant="h6" className="mb-1" component="h1">
        When using the TLS-15, please, cite the following sources:
      </Typography>
      <div className="text-left refList">
        {REFS.map((ref) => (ref.type === 'book' ? <BookRef key={ref.title} {...ref} /> : <Ref nobold key={ref.doi} {...ref} />))}
      </div>
      <ul className="languages-list">
        {LANGUAGES.map((lang) => (
          <li key={lang.code}>
            <Flag height="15" width="20" code={lang.code} />
            <a href={`/tls/${lang.lang}`}> {lang.language}</a>
          </li>
        ))}
      </ul>
      <Button href="/tls/" className="ml-3" variant="contained" color="primary">
        Go back
      </Button>
    </Container>
  );
}
