import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';


export default class Hints extends React.Component {
    constructor(props) {
        super();
        this.state = {
            numberOfHints: props.hints.length,
            currentHint: ((props.question_id === 8 && props.challenge) ? 1 : 0),
            text: props.hints[props.question_id === 8 && props.challenge ? 1 : 0].text.replace("[nick]", props.nick)
        };
    }

    getText() {
        let hintText = this.state.text;
        if(this.props.question_id === 40 && this.props.playerFollows<=this.props.monicaFollows){
            hintText = this.state.currentHint === 0 ? "So during the next meeting with your friends, when Alex asked you and Monica how are things going with your Social Media adventure, you both looked ashamed and said that even though you did your best to produce the most beautiful pictures, people did not like that!" : hintText;
            hintText = this.state.currentHint === 1 ? hintText = "Alex asked what was the reason for your negative experiences. Before you could say anything, Monica replied:" : hintText;
            hintText = this.state.currentHint === 2 ? hintText = "I think it’s because we were not real… We tried to do what everyone does these days – photoshop our pictures… But I guess people are all fed up with these seemingly perfect models. They are all like fake detectors! Even small alterations make them uninterested." : hintText;
        }
        hintText = hintText.replace("[nick]", this.props.nick);
        hintText = "<p>" + hintText.replace(this.props.nick, "<b class='nickName'>" + this.props.nick + "</b>") + "</p>";
    

        return hintText;
    }
    nextHint() {
        this.props.stopLikes();
        if (this.state.currentHint === this.state.numberOfHints - 1) {
            this.props.handleLastHint();
        } else {
            this.setState({
                currentHint: this.state.currentHint + 1,
                text: this.props.hints[this.state.currentHint + 1].text
            });
            if (this.props.hints[this.state.currentHint + 1].followers !== undefined) {
                let text = "<p>" + this.props.hints[this.state.currentHint + 1].text.replace("[newFollowers]", "<b class='follows'>" + this.props.updateStats(this.props.hints[this.state.currentHint + 1].followers) + "</b>") + "</p>";
                this.setState({ text: text });
            }
        };
    }
    prepareImgSrc(img, enemy = false) {
        let model = this.props.model;
        if (enemy) {
            model = model === "Kasia" ? "Paulina" : "Kasia";
        }
        return "/images/" + model + "/" + img + ".jpg";
    }


    render() {
        return (
            <Box textAlign="center" className={this.props.hints[this.state.currentHint].class}>
                <Box display="flex" flexWrap alignItems="center" mx="auto" justifyContent="center" className={this.props.hints[this.state.currentHint].image !== undefined ? "mainImage" : "main"} >
                    <Grid container spacing={3}>
                        <Grid item id="hint" xs={12}>{this.props.hints[this.state.currentHint].avatar === undefined ? <Box dangerouslySetInnerHTML={{ __html: this.getText() }} /> :
                            <Grid container id="hint" xs={12}>
                                <Grid item xs={2}><Avatar className="avatar" alt="someone" src={this.props.hints[this.state.currentHint].avatar} /></Grid>
                                <Grid align="left" item xs={10} ><small className="comment-subsection">{this.props.hints[this.state.currentHint].mins} min ago</small><Box className="comment"><Box dangerouslySetInnerHTML={{ __html: this.getText() }} /></Box></Grid>
                            </Grid>
                        }
                        </Grid>
                        {this.props.hints[this.state.currentHint].image === undefined ? null : <Grid item xs={12}><img src={this.prepareImgSrc(this.props.hints[this.state.currentHint].image, this.props.hints[this.state.currentHint].enemy)} alt="model" /></Grid>}
                    </Grid>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Box display="inline" p="15px">
                        <Button variant="contained" onClick={this.nextHint.bind(this)}>Next</Button>
                    </Box>
                </Box>
            </Box>
        )
    }
}  
