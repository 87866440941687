import React, { useState } from 'react';
import '../../App.css';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';


export default function PasswordedContent() {
  return (
    <Container maxWidth="md">
      <Box pt={5} pb={3}>
          <Typography variant="h5" className="description" >
          <Box pb={5}>Drodzy Studenci,</Box>
          <Box>Zapraszam Państwa serdecznie do wzięcia udziału w badaniu dotyczącym preferencji partnerów:</Box>
          <Box> <a target="_blank" rel="noopener" href='https://uwr.fra1.qualtrics.com/jfe/form/SV_022c7X3rSMzM5Gm?set=1'>https://uwr.fra1.qualtrics.com/jfe/form/SV_022c7X3rSMzM5Gm?set=1</a>
          </Box>
          <Box pt={3}>Kolejne badanie, dotyczące postrzegania innych osób:</Box>
          <Box>(link do badania pojawi się na początku marca)</Box>
          <Box  pt={3}>Jeśli zaś sa Państwo zainteresowani tematyką gier ekonomicznych, to proponuję zobaczyć poniższe badanie:</Box>
          <Box><a target="_blank" rel="noopener" href='https://uwr.fra1.qualtrics.com/jfe/form/SV_1BNcN0LCTVsOS1f?CC=MK'>https://uwr.fra1.qualtrics.com/jfe/form/SV_1BNcN0LCTVsOS1f?CC=MK</a></Box>
          <Box  pt={3}> Zachęcam również do wzięcia udziału w badaniu dotyczącym atrakcyjności fizycznej, miłości, oraz preferencji partnerów:</Box>
          <Box>(link do badania pojawi się pod koniec marca)</Box>
          <Box pt={3}>Z góry bardzo dziękuję za poświęcony czas na udział w badaniu,</Box>
          <Box>Marta Kowal</Box>
        </Typography>
      </Box>
    </Container>
  );
}


