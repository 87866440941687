import React from 'react';
import Start from './Start';
import Question from './Question';


export default class Main extends React.Component {
	constructor(props) {
		super();
		this.state = {
			gameStarted: false
		}
	}

	startGame() {
		this.setState({ gameStarted: true });
		document.getElementsByClassName('hideNav')[0].style.display = "none";
	}

	render() {
		return (
			<div id="game" class="h-100 d-flex justify-content-center align-items-center">
				{this.state.gameStarted === false ? <Start start={this.startGame.bind(this)} /> : <Question lang="english" />}
			</div>
		)
	}
}  
