import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import data from './data.json'
import Box from '@material-ui/core/Box';
import Questionnaire from '../Questionnaire/Questionnaire';
import GaugeChart from 'react-gauge-chart'
import Drawer from '@material-ui/core/Drawer';
import { Typography } from '@material-ui/core';
import Hints from "./Hints";
import Grid from '@material-ui/core/Grid';
import { Fireworks } from 'fireworks/lib/react'
import { GoogleSpreadsheet } from "google-spreadsheet";
import $ from "jquery";

export default class Question extends React.Component {
	constructor(props) {
		super();
		const questions = data[props.lang];
		this.state = {
			playerFollows: 0,
			monicaFollows: 0,
			question_id: questions.question_1.id,
			question: questions.question_1.question,
			first_answer_id: questions.question_1.answers.first_answer.id,
			firstNext: questions.question_1.answers.first_answer.nextQuestionId,
			first_Answer: questions.question_1.answers.first_answer.text,
			second_answer_id: questions.question_1.answers.second_answer.id,
			secondNext: questions.question_1.answers.second_answer.id,
			second_answer: questions.question_1.answers.second_answer.text,
			startDate: Date.now()
		}
	

	}

	checkUserData = async () => {
		return $.getJSON('https://json.geoiplookup.io/', function (data) {
			return JSON.stringify(data, null, 2);
		})
		console.log(userData.ip);
		this.setState({userData:data});

	}

	dataSave = async () => {
		this.setState({userData: await this.checkUserData()})
	}
	componentDidMount() {
		this.dataSave();
		this.prepareRandSetsAndModel();
		if (this.state.question_id === 6) {
			let text = "<p>" + this.state.question.replace(this.state.nick, "<b class='nickName'>" + this.state.nick + "</b>") + "</p>";
			document.getElementsByClassName('main')[0].innerHTML = text;
		}
	}


	updateQuestion(id, e) {
		const question = data[this.props.lang]["question_" + id];
		if(this.state.questionnaire !== undefined){
			this.state.firstQuestionaireDone === undefined ? this.saveData(this.state) : "";
			this.setState({firstQuestionaireDone: true});
			
		}
		if (this.state.hintsDone) {
			this.setState({ hintsDone: undefined });
		};
		if (question.question.includes("[nick]")) {
			if(this.state.nick === undefined){
			document.getElementById('nickName').value = document.getElementById('nickName').value === "" ? "Anonymous" : document.getElementById('nickName').value;
			question.question = question.question.replace("[nick]", "<b class='nickName'>" + document.getElementById('nickName').value + "</b>");
			this.setState({ nick: document.getElementById('nickName').value })
		}
			else {
				question.question = question.question.replace("[nick]", "<b class='nickName'>" + this.state.nick + "</b>");
			}
		};
		if (e !== undefined && (parseInt(e.currentTarget.id) === 8 || parseInt(e.currentTarget.id) === 10)) {
			this.setState({ challenge: true })
		};
		this.setState({
			followers: question.followers,
			question_id: question.id,
			question: question.question,
			first_answer_id: question.answers.first_answer.id,
			firstNext: question.answers.first_answer.nextQuestionId,
			first_Answer: question.answers.first_answer.text,
			second_answer_id: question.answers.second_answer.id,
			secondNext: question.answers.second_answer.nextQuestionId,
			second_answer: question.answers.second_answer.text,
			hints: question.hints,
			image: question.image
		});
		if (question.followers !== undefined) {
			let text = "<p>" + question.question.replace("[newFollowers]", "<b class='follows'>" + this.updateStats(question.followers) + "</b>") + "</p>";
			if (document.getElementsByClassName('main')[0] !== undefined) {
				document.getElementsByClassName('main')[0].innerHTML = text
			} else {
				document.getElementsByClassName('mainImage')[0].innerHTML = text
			}
		}
		if (question.question.includes("[PlayerFollowersSum]")) {
			let text = "<p>" + question.question.replace("[PlayerFollowersSum]", "<b class='follows'>" + this.state.playerFollows + "</b>") + "</p>";
			text = text.replace("[MonicaFollowersSum]", "<b class='follows'>" + this.state.monicaFollows + "</b>")
			this.setState({ question: text })
		}
	}

	updateStats(followers) {
		let value = Math.floor(Math.random() * (followers.max - followers.min) + followers.min);
		if (followers.person === "Player") {
			this.setState({
				playerFollows: this.state.playerFollows + value,
				newFollowersPlayer: value
			});
		} else {
			this.setState({
				monicaFollows: this.state.monicaFollows + value,
				newFollowersMonica: value,
				monicaLikes: true
			});
		}
		return value;
	}

	prepareRandSetsAndModel() {
		let firstSet = new Array(10);
		let secondSet = new Array(10);
		for (let i = 0; i < firstSet.length; i++) {
			firstSet[i] = i + 1;
		}
		firstSet.sort(() => Math.random() - 0.5);
		secondSet = firstSet.splice(0, 5);

		const min = 1;
		const max = 100;
		const rand = min + Math.random() * (max - min);;

		this.setState({
			firstSet: firstSet,
			secondSet: secondSet,
			model: rand >= 50 ? "Kasia" : "Paulina"
		});

	}

	handleQuestionnaire(rating) {
		this.state.questionnaire === undefined ? this.setState({ questionnaire: rating }) : this.setState({ questionnaireFinal: rating });
		this.state.question_id === 99 ? this.updateQuestion(5) : this.updateQuestion(101);
	}

	handleLastHint() {
		this.setState({
			hintsDone: true
		});
	}

	prepareImgSrc(img, enemy = false) {
		let model = this.state.model;
		if (enemy) {
			model = model === "Kasia" ? "Paulina" : "Kasia";
		}
		return "/images/" + model + "/" + img + ".jpg";
	}
	generateLikes(number,side){
		let tab = []
		for(let i=0; i<number;i++){
			let styles = side === "left" ? {
				left:  Math.floor(Math.random() * 90) + "%",
				top:  Math.floor(Math.random() * 20) + "%" ,
				animation: "falling " + ((Math.random() * (5-3)) + 3) + "s " + 3} : 
				{
					right:  Math.floor(Math.random() * 90) + "%",
					top:  Math.floor(Math.random() * 20) + "%" ,
					animation: "falling " + ((Math.random() * (5-3)) + 3) + "s " + 3};
			tab.push(<div style={styles} className={"likes-"+side}><img width="50px" src="/images/floating/12.png" alt="model" /></div>);
		}
		return tab;	 	
	}
	stopLikes(){
		this.setState({monicaLikes: false});
	}
	async appendSpreadsheet(row){
		const SPREADSHEET_ID = "1VZgIrLiGtGony0vhEU_MDQ046MHG-dFuENObum8TyY8";
		const SHEET_ID = "1322069976";
		const CLIENT_EMAIL = "marta-website@martawebsite.iam.gserviceaccount.com";
		const PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC+s079q0os2EZm\n9MRhpBzvmrr83ZFUJh1GZPbuXgYr0LOyzW/6HRmX9FSo7i+cB8CK7GMklxyPK5vI\nrbAcWJhFnN3DarwP54CeKgcVwXHhYkn7ZaU1MjgIBZYZDVtBdaURwGUhtyadS+GY\nJ4PcwUS5swN8IJGUfkoXfljSuWDQ081Qj1m0xjaFjlSQrjDnGM1dnfeFRI7wAd30\nvGmwK2h1hx7QnJjYgO+12fcz7fWACbAv7Z9n+/Jq/M2cBI5YpIQTqrs6n6VWMcrO\nIKM9h+FmBX9S0qKe+r7lXeBRroWWVjVMgp8vrz7NdRSsdTWGTNxQvpFWH4H7VnF6\nDWTYue9/AgMBAAECggEAHHabTSh3tJ7T+e0Bsh3dMHQqAbEOeSlhMuRsqOJsIwu5\nA/CUN0LdqD0QinsBVyrJtdKXmlqwVBGVucvBiVGTP3ICSvck5RjQLTX9AxBxr3V3\nW73C/WAmqyfY90SeQMnO9AnWmRmC+spcfH81YsnkDfWRXmxms5Zv96Fk9OWKH6ZN\neF4eDgsOc1C1ij4nXiknZ3EzCKp9jDJu79x/VqlGZRHDpMVE5F225DlabuZ4TDJ2\nBHkvjuOM3KeOeyeEY+cGE/+szdJzI7CFp57UEkzmFKLsIFJmkWvCqmHgyzYgF2Zw\nM9Mrbf+b+CHP3Wuu0wyLc6l4k4wJ3QCWh2p0HG81VQKBgQD89cs3a8PO6a8a22Sr\nPd8mNAWwKo1KbnwwVxvG0FZsU/BK4/mY51v3uFoVjxxd+caxNjDSB2M1AqJfSdd0\nz/yLngC6ugF5zqdjR7sgLH2zqnCwFp2U6LYDBhvUonSIHmN0OdHNH2RJi5pDw3eb\n8Jh8fC5x3R6k/3vk5BQU2O3rgwKBgQDA/fqkL1dr19xBIznxcTkfnb5EOeEhlalG\nok4Z0lMlGOIXBprekycpC4H3dH4BYJTy+Tetr8u9koemm/X8pKcQVNE3MOqQZ/ln\nZQ89He8hNGQHV/ys60KtXGXNvEd7Zw3bHE/XfV41JtWn9aW7tcNZNUDtf62mj2pk\nhta7May/VQKBgC8puLDlk8VxRmeQfi17RK6/xku5M0ysWtU8eH5R+7sIz2V3KeM7\nR6dhJNKeMyDGYChMPT/7hqCslA+GGfi6ipCRbftikMHgKM9W4Wm3QdyMq6P5I3pP\nBRh9PBUiEO4MFGjoGtjOwIkB0UpPGG7npdaX5KTwIDWNONFNQP0+EOX3AoGAbqfx\ns3w1iu89KdgR3B9IWpluw93P+Rq/8XEUiPewlr+6uusdl2sWdBFWFtWjfhSrstk0\nlJEMqAjBYMVh+NdLtzB6uUOB6wTC2wb2AtwMBaTticxoY5mNJKuq75j5/4aqSizk\nlrEaeC1pKiHOQl/Iv3iqXsLrhWoL3dH2g2TLIPUCgYADiMD8LTf55gfmZ04pp/9H\n9L62WaVaBdlqCAIdZ4aYJMw3uJRteAKDnoD+La/SS6DWlD1GbkwgJdXkOEx8tYLF\nszxqIJaxXI4hax5E/QTdtWvUTSBVPLyscAIgRQC7QX2W5wz116uuijV73l/UULN7\nqPhvZys7FFo0kvpUM5QFwg==\n-----END PRIVATE KEY-----\n";
		const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
        try {
            await doc.useServiceAccountAuth({
                client_email: CLIENT_EMAIL,
                private_key: PRIVATE_KEY,
            });
            await doc.loadInfo();
            const sheet = doc.sheetsById[SHEET_ID];
            const result = await sheet.addRow(row);
        } catch (e) {
            console.error('Error: ', e);
        }
    };
	saveData(data){
        const newRow = {
            UserName: data.nick !== undefined ? data.nick : "",
			UserModel: data.model,
			PlayerFollowers: data.playerFollows !== undefined ? data.playerFollows : "",
			MonicaFollowers: data.monicaFollows !== undefined ? data.monicaFollows : "",
			Q_Pic_1_rate: data.questionnaire.rate_1,
			Q_Pic_2_rate: data.questionnaire.rate_2,
			Q_Pic_3_rate: data.questionnaire.rate_3,
			Q_Pic_4_rate: data.questionnaire.rate_4,
			Q_Pic_5_rate: data.questionnaire.rate_5,
			Q_BodyDissatisfaction: data.questionnaire.bodyDissatisfaction,
			Q_BodyAccept: data.questionnaire.bodyAccept,
			Q_ChangeAppearance: data.questionnaire.changeAppearance,
			Q_Age: data.questionnaire.age,
			Q_Country: data.questionnaire.country,
			Q_Sex: data.questionnaire.sex,
			Q2_Pic_1_rate: data.questionnaireFinal !== undefined ? data.questionnaireFinal.rate_1 : "",
			Q2_Pic_2_rate: data.questionnaireFinal !== undefined ? data.questionnaireFinal.rate_2 : "",
			Q2_Pic_3_rate: data.questionnaireFinal !== undefined ? data.questionnaireFinal.rate_3 : "",
			Q2_Pic_4_rate: data.questionnaireFinal !== undefined ? data.questionnaireFinal.rate_4 : "",
			Q2_Pic_5_rate: data.questionnaireFinal !== undefined ? data.questionnaireFinal.rate_5 : "",
			Q2_BodyDissatisfaction: data.questionnaireFinal !== undefined ? data.questionnaireFinal.bodyDissatisfaction : "",
			Q2_BodyAccept: data.questionnaireFinal !== undefined ? data.questionnaireFinal.bodyAccept : "",
			Q2_ChangeAppearance: data.questionnaireFinal !== undefined ? data.questionnaireFinal.changeAppearance : "",
			StartDate: data.startDate,
			FinishDate: data.nick !== undefined ? Date.now() : "",
			Time: data.nick !== undefined ? (Date.now() - data.startDate) / 1000 : "",
			Date: new Date().toISOString(),
			Ip: data.userData.ip,
			UserCountry: data.userData.country_name,
			UserCity: data.userData.city
		};
        this.appendSpreadsheet(newRow);
    }
	fireworks(){
		this.saveData(this.state);
		document.getElementsByClassName('hideNav')[0].style.display = "block";
		let fxProps = {
			count: 5,
			interval: 1500,
			colors: ['#cc3333', '#4CAF50', '#FFFF00'],
			calc: (props, i) => ({
			  ...props, 
			  x:  Math.floor(Math.random() * window.innerWidth-0.2* window.innerWidth) + 0.1* window.innerWidth,
			  y:  Math.floor(Math.random() * window.innerHeight-0.4*window.innerHeight) + 0.2*window.innerHeight
			})
		  }
		return fxProps;

	}
	render() {
		if (this.state.question_id === 99 || this.state.question_id === 100) {
			return (
				<Questionnaire question_id={this.state.question_id} firstQuestionaire={this.state.questionnaire === undefined ? true : false} picsSet={this.state.questionnaire === undefined ? this.state.firstSet : this.state.secondSet} handleQuestionnaire={this.handleQuestionnaire.bind(this)} />
			)
		}
		else {
			return (
				<>
					{this.state.question_id === 101 ? <Fireworks {...this.fireworks()} /> : ""	}
					{this.state.question_id <= 7 ? "" :
						<Drawer anchor="left" variant="permanent" open={true} classes={{ paper: "drawer-background" }}>
							<Box color="white" align="center">
								<Typography variant="h4" >
									{this.state.nick}
								</Typography>
							</Box>
							{this.state.followers !== undefined ?
							<Box id="likes-Player">
								 {this.generateLikes(10,"left").map((like) => { return like }) }
							</Box> : "" }
							<Box className="gauge">
								<GaugeChart id="playerGauge"
									animate={true}
									nrOfLevels={3}
									percent={this.state.playerFollows / 300}
									textColor="#fff"
									colors={["#FF0000", "#00FF00"]}
									formatTextValue={value => Math.floor(value * 3)}
									needleColor="#345243"
								/>
							</Box>
						</Drawer>
					}
					{this.state.question_id <= 7 ? "" :
						<Drawer anchor="right" variant="permanent" open={true} classes={{ paper: "drawer-background" }}>
							<Box color="white" align="center">
								<Typography variant="h4" >
									Monica
							</Typography>
							</Box>
							{this.state.monicaLikes ?
							<Box>
								 {this.generateLikes(5,"right").map((like) => { return like }) }
							</Box> : "" }
							<Box className="gauge">
								<GaugeChart id="monicaGauge"
									animate={true}
									nrOfLevels={3}
									percent={this.state.monicaFollows / 400}
									textColor="#fff"
									colors={["#FF0000", "#00FF00"]}
									formatTextValue={value => Math.floor(value * 4)}
									needleColor="#345243"
								/>
							</Box>
						</Drawer>}

					{
						this.state.hints !== undefined && this.state.hintsDone === undefined ?
							<Hints hints={this.state.hints} question_id={this.state.question_id} playerFollows={this.state.playerFollows} monicaFollows={this.state.monicaFollows} challenge={this.state.challenge} stopLikes={this.stopLikes.bind(this)} nick={this.state.nick} handleLastHint={this.handleLastHint.bind(this)} model={this.state.model} updateStats={this.updateStats.bind(this)} /> :
							<Box textAlign="center">
								<Box display="flex" alignItems="center" mx="auto" justifyContent="center" className={this.state.question_id === 101 ? "main-end": this.state.image === undefined ? "main" : "mainImage"} >
									<Grid container spacing={3}>
										<Grid item xs={12}>
											{this.state.question_id === 101 ? <div>
												<p>Thank you for playing!</p>
												<p>If you are interested in expanding our project, please, share the link to the app with your friends and post it on your social media. We would really appreciate that!</p>
												<p>If you have any questions or suggestions, write to us: <br></br><a href="mailto:press.report.study1@gmail.com">press.report.study1@gmail.com</a></p>
												<span>Principal Investigator of the project:</span> <br></br>
												<span>PhD candidate Marta Kowal <br></br>Institute of Psychology, University of Wroclaw, Poland</span> <br></br>
												<a target="_blank" rel="noopener noreferrer" href="https://scholar.google.com/citations?user=US0485YAAAAJ&hl=pl&oi=ao">Google Scholar</a> <br></br>	
												<a target="_blank" rel="noopener noreferrer" href="https://www.researchgate.net/profile/Marta_Kowal">ResearchGate</a> <br></br>
												<a target="_blank" rel="noopener noreferrer" href="https://twitter.com/Marta7Kowal">Twitter</a>	<br></br><br></br>
												<p>Also go check out <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/medbabki/">Instagram</a> profile of our models: Katarzyna Lizak and Paulina Basta.</p>
											</div> : <div dangerouslySetInnerHTML={{ __html: this.state.question }} />}
											
										</Grid>
										{this.state.image !== undefined ? <Grid item xs={12}><img src={this.prepareImgSrc(this.state.image)} alt="model" /></Grid> : null}
									</Grid>
								</Box>
								<Box display="flex" alignItems="center" justifyContent="center">
									{
										this.state.question_id === 5 ?
											<Box display="inline" p="15px">
												<TextField inputProps={{ maxlength: 15 }} id="nickName" label="Nick" />
											</Box>
											: ""
									}
									{this.state.question_id === 101 ? "" : <>
										<Box display="inline" p="15px">
											<Button variant="contained" id={this.state.first_answer_id} onClick={this.updateQuestion.bind(this, this.state.firstNext)} data-next={this.state.firstNext} color="primary">{this.state.first_Answer}</Button>
										</Box>
										{
											this.state.second_answer_id === 0 ? "" :
												<Box display="inline" p="15px">
													<Button variant="contained" id={this.state.second_answer_id} onClick={this.updateQuestion.bind(this, this.state.secondNext)} data-next={this.state.secondNext} color="primary">{this.state.second_answer}</Button>
												</Box>
										}</>}
								</Box>
							</Box>
					}

				</>
			)
		}
	}
}  
