import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import { Button, Typography } from '@material-ui/core';
import Flag from 'react-world-flags';
import { LANGUAGES } from '../StaticData';

export default function TestTLS() {
  return (
    <Container maxWidth="md">
      <Typography variant="h5" className="my-5" component="h1">
        Choose your language:
      </Typography>
      <ul className="languages-list">
        {LANGUAGES.map((lang) => (
          <li key={lang.code}>
            <Flag height="15" width="20" code={lang.code} />
            <a href={`/tls/test/${lang.lang}`}> {lang.language}</a>
          </li>
        ))}
      </ul>
      <Button href="/tls/" className="ml-3" variant="contained" color="primary">
        Go back
      </Button>
    </Container>
  );
}
