import React, { useState } from 'react';
import '../../App.css';
import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import Link from '@material-ui/core/Link';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import ReactLoading from 'react-loading';
import $ from 'jquery';

export default function Join() {
  const [consent, setConsent] = React.useState('false');
  const [country, setCountry] = React.useState(null);
  const [primaryLang, setPrimaryLang] = React.useState(null);
  const [participants, setParticipants] = React.useState(null);
  const [firstName, setFirstName] = React.useState(null);
  const [middleName, setMiddleName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [department, setDepartment] = React.useState(null);
  const [institution, setInstitution] = React.useState(null);
  const [institutionTown, setInstitutionTown] = React.useState(null);
  const [institutionCountry, setInstitutionCountry] = React.useState(null);
  const [affiliations, setAffiliations] = React.useState(null);
  const [comments, setComments] = React.useState(null);
  const [signed, setSigned] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [userData, setData] = React.useState({});
  const [response, setResponse] = React.useState(null);
  const SPREADSHEET_ID = '1VZgIrLiGtGony0vhEU_MDQ046MHG-dFuENObum8TyY8';
  const SHEET_ID = '1478410354';
  const CLIENT_EMAIL = 'marta-website@martawebsite.iam.gserviceaccount.com';
  const PRIVATE_KEY =
    '-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC+s079q0os2EZm\n9MRhpBzvmrr83ZFUJh1GZPbuXgYr0LOyzW/6HRmX9FSo7i+cB8CK7GMklxyPK5vI\nrbAcWJhFnN3DarwP54CeKgcVwXHhYkn7ZaU1MjgIBZYZDVtBdaURwGUhtyadS+GY\nJ4PcwUS5swN8IJGUfkoXfljSuWDQ081Qj1m0xjaFjlSQrjDnGM1dnfeFRI7wAd30\nvGmwK2h1hx7QnJjYgO+12fcz7fWACbAv7Z9n+/Jq/M2cBI5YpIQTqrs6n6VWMcrO\nIKM9h+FmBX9S0qKe+r7lXeBRroWWVjVMgp8vrz7NdRSsdTWGTNxQvpFWH4H7VnF6\nDWTYue9/AgMBAAECggEAHHabTSh3tJ7T+e0Bsh3dMHQqAbEOeSlhMuRsqOJsIwu5\nA/CUN0LdqD0QinsBVyrJtdKXmlqwVBGVucvBiVGTP3ICSvck5RjQLTX9AxBxr3V3\nW73C/WAmqyfY90SeQMnO9AnWmRmC+spcfH81YsnkDfWRXmxms5Zv96Fk9OWKH6ZN\neF4eDgsOc1C1ij4nXiknZ3EzCKp9jDJu79x/VqlGZRHDpMVE5F225DlabuZ4TDJ2\nBHkvjuOM3KeOeyeEY+cGE/+szdJzI7CFp57UEkzmFKLsIFJmkWvCqmHgyzYgF2Zw\nM9Mrbf+b+CHP3Wuu0wyLc6l4k4wJ3QCWh2p0HG81VQKBgQD89cs3a8PO6a8a22Sr\nPd8mNAWwKo1KbnwwVxvG0FZsU/BK4/mY51v3uFoVjxxd+caxNjDSB2M1AqJfSdd0\nz/yLngC6ugF5zqdjR7sgLH2zqnCwFp2U6LYDBhvUonSIHmN0OdHNH2RJi5pDw3eb\n8Jh8fC5x3R6k/3vk5BQU2O3rgwKBgQDA/fqkL1dr19xBIznxcTkfnb5EOeEhlalG\nok4Z0lMlGOIXBprekycpC4H3dH4BYJTy+Tetr8u9koemm/X8pKcQVNE3MOqQZ/ln\nZQ89He8hNGQHV/ys60KtXGXNvEd7Zw3bHE/XfV41JtWn9aW7tcNZNUDtf62mj2pk\nhta7May/VQKBgC8puLDlk8VxRmeQfi17RK6/xku5M0ysWtU8eH5R+7sIz2V3KeM7\nR6dhJNKeMyDGYChMPT/7hqCslA+GGfi6ipCRbftikMHgKM9W4Wm3QdyMq6P5I3pP\nBRh9PBUiEO4MFGjoGtjOwIkB0UpPGG7npdaX5KTwIDWNONFNQP0+EOX3AoGAbqfx\ns3w1iu89KdgR3B9IWpluw93P+Rq/8XEUiPewlr+6uusdl2sWdBFWFtWjfhSrstk0\nlJEMqAjBYMVh+NdLtzB6uUOB6wTC2wb2AtwMBaTticxoY5mNJKuq75j5/4aqSizk\nlrEaeC1pKiHOQl/Iv3iqXsLrhWoL3dH2g2TLIPUCgYADiMD8LTf55gfmZ04pp/9H\n9L62WaVaBdlqCAIdZ4aYJMw3uJRteAKDnoD+La/SS6DWlD1GbkwgJdXkOEx8tYLF\nszxqIJaxXI4hax5E/QTdtWvUTSBVPLyscAIgRQC7QX2W5wz116uuijV73l/UULN7\nqPhvZys7FFo0kvpUM5QFwg==\n-----END PRIVATE KEY-----\n';
  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

  const check = async () => {
    try {
      return await $.getJSON('https://json.geoiplookup.io/', function (data) {
        return JSON.stringify(data, null, 2);
      });
    } catch (err) {
      return {};
    }
  };

  const appendSpreadsheet = async (row) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      await doc.loadInfo();
      const sheet = doc.sheetsById[SHEET_ID];
      const result = await sheet.addRow(row);
      await setResponse(await result);
    } catch (e) {
      console.error('Error: ', e);
    }
  };

  const capitalizeFirstLetter = (phrase) => {
    return phrase !== null
      ? phrase
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      : null;
  };

  const handleConsent = async (event) => {
    setData(await check());
    setConsent(event.target.value);
  };

  const handleForm = () => {
    if (
      consent === 'true' &&
      country !== null &&
      primaryLang !== null &&
      participants !== null &&
      firstName !== null &&
      lastName !== null &&
      email !== null &&
      institution !== null &&
      institutionTown !== null &&
      department !== institutionCountry
    ) {
      saveData(userData);
      setError(false);
      setSigned(true);
    } else {
      setError(true);
    }
  };

  const saveData = (userData) => {
    const newRow = {
      Consent: consent,
      Country: capitalizeFirstLetter(country),
      Primary_Lang: capitalizeFirstLetter(primaryLang),
      Participants: capitalizeFirstLetter(participants),
      First_Name: capitalizeFirstLetter(firstName),
      Middle_Name: capitalizeFirstLetter(middleName),
      Last_Name: capitalizeFirstLetter(lastName),
      Email: capitalizeFirstLetter(email),
      Department: department,
      Institution: institution,
      Institution_Town: institutionTown,
      Institution_Country: institutionCountry,
      Affiliations: affiliations,
      Comments: comments,
      Ip: userData.ip || 0,
      UserCountry: userData.country_name || 'Addbock',
      UserCity: userData.city || 'Addblock',
      Date: new Date().toISOString().slice(0, 10),
    };
    appendSpreadsheet(newRow);
  };

  return (
    <Container maxWidth="md">
      {!signed ? (
        <Box>
          <Typography variant="h4" className="header" gutterBottom>
            Consent
          </Typography>
          <FormControl component="fieldset">
            <Typography variant="h6" gutterBottom>
              By filling in the questions below, you express your interest in joining our research team. We will contact collaborators with whom we
              would like to start a joint work via e-mail. Do you want to become an active collaborator in the large-scale cross-cultural project?
            </Typography>
            <FormLabel component="legend"></FormLabel>
            <RadioGroup aria-label="Consent" name="Consent" value={consent} onChange={handleConsent}>
              <FormControlLabel value={'true'} control={<Radio />} label="Yes" />
              <FormControlLabel value={'false'} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>

          {consent === 'true' ? (
            <Box pt={5}>
              <form noValidate autoComplete="off">
                <Typography variant="h6" gutterBottom>
                  1. In what country could you collect data (please, indicate the full, English name of the country)? If more than one, please,
                  indicate all countries separated by a comma.
                </Typography>
                <TextField
                  required
                  error={country === null ? true : false}
                  id="standard-full-width"
                  fullWidth
                  label="Country"
                  onChange={(e) => setCountry(e.target.value)}
                />

                <Typography variant="h6" gutterBottom>
                  2. What is the primary language spoken in the country that you could collect data?
                </Typography>
                <TextField
                  required
                  error={primaryLang === null ? true : false}
                  id="standard-full-width"
                  fullWidth
                  label="Primary Language"
                  onChange={(e) => setPrimaryLang(e.target.value)}
                />

                <Typography variant="h6" gutterBottom>
                  3. How many participants do you think you could recruit?
                </Typography>
                <TextField
                  required
                  error={participants === null ? true : false}
                  id="standard-full-width"
                  fullWidth
                  label="Participants"
                  onChange={(e) => setParticipants(e.target.value)}
                />

                <Typography variant="h6" gutterBottom>
                  4. Please, indicate your First name.
                </Typography>
                <TextField
                  required
                  error={firstName === null ? true : false}
                  id="standard-full-width"
                  fullWidth
                  label="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />

                <Typography variant="h6" gutterBottom>
                  5. Please, indicate the initial(s) of your middle name(s) (if any; if you prefer no middle name, leave blank).
                </Typography>
                <TextField id="standard-full-width" fullWidth label="Middle Name" onChange={(e) => setMiddleName(e.target.value)} />

                <Typography variant="h6" gutterBottom>
                  6. Please, indicate your Last name.
                </Typography>
                <TextField
                  required
                  error={lastName === null ? true : false}
                  id="standard-full-width"
                  fullWidth
                  label="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                />

                <Typography variant="h6" gutterBottom>
                  7. What is your e-mail address?
                </Typography>
                <TextField
                  required
                  error={email === null ? true : false}
                  id="standard-full-width"
                  fullWidth
                  label="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                />

                <Typography variant="h6" gutterBottom>
                  8. What is the name of the Department of your current Institution (e.g., Department of Neurological Science or Institute of
                  Psychology); leave blank if there is none to report.
                </Typography>
                <TextField id="standard-full-width" fullWidth label="Current Department" onChange={(e) => setDepartment(e.target.value)} />

                <Typography variant="h6" gutterBottom>
                  9. What is the general name of your current institution (e.g., University of Stanford, University of Wroclaw)?
                </Typography>
                <TextField
                  required
                  error={institution === null ? true : false}
                  id="standard-full-width"
                  fullWidth
                  label="Current Institution"
                  onChange={(e) => setInstitution(e.target.value)}
                />

                <Typography variant="h6" gutterBottom>
                  10. What is the town of your current institution (e.g., London, Berlin, Warsaw)?
                </Typography>
                <TextField
                  required
                  error={institutionTown === null ? true : false}
                  id="standard-full-width"
                  fullWidth
                  label="Current Institution Town"
                  onChange={(e) => setInstitutionTown(e.target.value)}
                />

                <Typography variant="h6" gutterBottom>
                  11. What is the country of your current institution (e.g., United Kingdom, United States, Poland)?
                </Typography>
                <TextField
                  required
                  error={institutionCountry === null ? true : false}
                  id="standard-full-width"
                  fullWidth
                  label="Current Institution Country"
                  onChange={(e) => setInstitutionCountry(e.target.value)}
                />

                <Typography variant="h6" gutterBottom>
                  12. If you prefer more than one institution listed as your affiliation, please, add the full details below.
                </Typography>
                <TextField id="standard-full-width" fullWidth label="Additional Affiliation" onChange={(e) => setAffiliations(e.target.value)} />

                <Typography variant="h6" gutterBottom>
                  13. If you have any other comments or suggestions, please, feel free to add them here.
                </Typography>
                <TextField id="standard-full-width" fullWidth label="Comments" onChange={(e) => setComments(e.target.value)} />
                {error ? (
                  <Box my={5} align="center">
                    <Typography variant="h3" color="error">
                      Please, fill missing information.
                    </Typography>
                  </Box>
                ) : (
                  ''
                )}
                <Box my={10} align="center" bgcolor="background.paper">
                  <Button variant="contained" size="large" color="primary" endIcon={<CheckIcon />} onClick={handleForm}>
                    Send form
                  </Button>
                </Box>
              </form>
            </Box>
          ) : (
            ''
          )}
        </Box>
      ) : (
        <Box my={5} align="center" bgcolor="background.paper">
          {response === null ? (
            <Box>
              <Typography variant="h4" className="header" gutterBottom>
                Saving your data!
              </Typography>
              <ReactLoading type="spinningBubbles" color="#000" height={'20%'} width={'20%'} />
            </Box>
          ) : (
            <Box>
              <Typography variant="h4" className="header" gutterBottom>
                Thank you!
              </Typography>
              <Typography variant="h5" className="header" gutterBottom>
                We will contact you briefly with further instructions.
              </Typography>
              <Box my={10}>
                <Link href="/home">
                  <Button variant="contained" size="large" color="primary">
                    Go back to the main page
                  </Button>
                </Link>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Container>
  );
}
