import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

export default class Start extends React.Component {
  constructor(props){
		super();
		this.start = props.start;
		
	}

	randomizePos(){
		let left = Math.floor(Math.random() * window.innerWidth)- 100;
		let top = Math.floor(Math.random() * window.innerHeight) * 0.5;
		let time = Math.floor(Math.random() * 10) + 5;
		 return {left: left + "px", top: top + 'px', animation: "falling " + time + "s infinite" }; 
	}
	
	render() {
		return	(
			<>
				<Box textAlign="center">
					<Box  display="flex" alignItems="center" mx="auto" justifyContent="center" className={"main"} >
						Conquer the world of Social Media! Create your profile and get as many followers as you can.
					</Box>
					<Box display="flex" alignItems="center" justifyContent="center">
						<Box display="inline" p="15px">
							<Button variant="contained" onClick={this.start} color="primary">Start the game</Button>
						</Box>
					</Box>
				</Box>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/1.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/2.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/3.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/3.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/3.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/3.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/3.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/4.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/5.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/6.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/7.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/8.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/9.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/10.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/11.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/13.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/14.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/14.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/14.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/14.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/14.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/14.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/15.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/16.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/17.png' alt="model" /></div>
				<div style={ this.randomizePos() } className="image-fly"><img width="50px" src='/images/floating/18.png' alt="model" /></div>


			</> 
		)
	}
}  
