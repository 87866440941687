import React, { useState } from 'react';
import '../../App.css';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';
import Ref from '../reference/Ref';
import ReactHtmlParser from 'react-html-parser';

export default function Papers() {
  const data = require('../../papers.json');
  const generateSections = () => {
    let sections = [];
    Object.keys(data.publications).map((date) => {
      sections.unshift(
        <Box pb={3} pt={2}>
          <Box pb={2}>
            <Typography className="sectionName" variant="h5">
              <b>{date}</b>
              <hr className="yearDivider" />
            </Typography>
          </Box>
          {data.publications[date].map((paper) => (
            <Ref
              reference={paper.reference}
              journal={paper.journal}
              issue={paper.issue}
              pages={paper.pages}
              doi={paper.doi}
              forecasting={paper.forecasting}
              book={paper.book}
            />
          ))}
        </Box>
      );
    });
    return sections;
  };

  return (
    <Container maxWidth="md">
      <Box pt={5} pb={10}>
        <Typography className="sectionName" variant="h4">
          <b>Publications</b>
          <hr className="headerDivider" />
        </Typography>
        <Box pb={3}>
          <Typography variant="h6">
            For a full list of my publications, see also:
            <Link
              target="_blank"
              rel="noopener"
              href="https://scholar.google.com/citations?user=US0485YAAAAJ"
            >
              <img className="icon-bottom" src="Scholar.svg" width="30px" />
            </Link>
          </Typography>
        </Box>
        {generateSections()}
      </Box>
    </Container>
  );
}
