import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { Button } from '@material-ui/core';

export default function ResearchTLSlang() {
  const data = require('../TranslationsData.json');

  const { lang } = useParams();
  const LangData = data[lang.toLowerCase()];

  const handleDownload = () => {
    const link = document.createElement('a');
    link.download = LangData.fileName;
    link.href = `/PDF/${lang.toUpperCase()}.pdf`;
    link.click();
  };

  return (
    <Container className="mt-5" maxWidth="md">
      <div>{LangData.intro}</div>
      <div>{`${LangData.rateMin}, ${LangData.rateMax}`}</div>
      <ul>
        {LangData.questions.map((question) => (
          <li key={question}>{question}</li>
        ))}
      </ul>
      <div className="mt-5">
        <h3 style={{ textDecoration: 'underline' }}>SCORING</h3>
        <p>
          The TLS-15 scale is divided into three subscales: Intimacy, Passion, and Commitment. The first five items (1-5) measure Intimacy, the next
          five (6-10) assess Passion, and the final five (11-15) tap into Commitment.
        </p>{' '}
        <p>
          {' '}
          To get a composite score for each dimension (Intimacy, Passion, Commitment), one can average the scores on those specific items.
          Alternatively, one can average all 15 items for a single overall score for the entire scale.
        </p>
      </div>
      <div className="my-5">
        <Button download variant="contained" color="primary" onClick={handleDownload}>
          Download PDF
        </Button>
        <Button href="/tls/" className="ml-3" variant="contained" color="primary">
          Go back
        </Button>
      </div>
    </Container>
  );
}
