import React from 'react';
import Box from '@material-ui/core/Box';
import ReactHtmlParser from 'react-html-parser';

export default function Ref(props) {
  const prepareReference = (reference) => {
    return props.nobold ? reference : ReactHtmlParser(reference.replace('Kowal, M', '<b>Kowal, M</b>'));
  };

  return (
    <Box my={0}>
      <Box pb={0} className="ref wrap">
        {prepareReference(props.reference)} <i>{props.journal}</i>
        {props.issue}
        {props.pages !== '' ? ',' : ''}
        {props.pages !== '' ? ' ' + props.pages : ''}.{' '}
        {props.forecasting ? (
          <>
            (<b>Member of Forecasting Collaboration</b>)
          </>
        ) : (
          ''
        )}
        <br></br>
        <a target="_blank" rel="noopener" href={props.doi}>
          {props.doi}
        </a>
      </Box>
    </Box>
  );
}
