import React, { useState } from 'react';
import '../../App.css';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Button, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import PasswordedContent from './PasswordedContent';


export default function ForStudents() {
  const [password, setPassword] = useState("");
  const [content, setContent] = useState('');

  
  const checkPassword = (e) => {
    e.preventDefault();
    password === "strona_mnie_lubi" ? 
      setContent(<PasswordedContent/>)
     : (alert("Wrong password"), false);
  }

  return (
    <Container maxWidth="md">
      <Box pt={5} pb={3}>
        { content !== "" ? content : 
        <Typography variant="h4" id="mainSection" className="sectionName">
        <b>Please, enter the password</b>
        <form onSubmit={checkPassword}>
        <FormControl>
          <TextField required type="password" error={password === null ? true : false} id="standard-full-width"  label="Password" onChange={(e) => setPassword(e.target.value)} />
          <Button type="submit" variant="contained" color="primary">Enter</Button>
        </FormControl>
        </form>
      </Typography>
      }
        
      </Box>
    </Container>
  );
}


